/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getBnbPrice } from "../../components/pool-card/pool-card";
import { PoolStatus } from "./components";
import { setLoading } from "../../logic/actions/loading.action";
import { PageContainer, Container, PoolDetail, PoolInfoEl } from "./style";
import {
  TokenType,
  getPoolContract,
  useScrollToTop,
  setStakeToken,
  setStakeTokenAmount,
  setTokenFromAmount,
  setStakeAmount,
  getUsdtContract,
  getUsdcContract,
  getDaiContract,
} from "../../utils";
import wallet from "../../utils/wallet";
import PoolDetPoolDetailsNew from "./components/pool-details-new/pool-details-new";
import { Game } from "../../core";
import Modal from "../../components/modal";
import "./style.scss";
import {
  AmountRaised,
  HeaderPrimary,
  RaiseContainer,
} from "./components/pool-status/style";
import { ethers } from "ethers";
import { withThreeDecimals } from "src/utils/number";

const userTokenAbi = require("../../utils/abis/user-token.json");

enum StartedProps {
  COMING,
  STARTED,
  FINISHED,
}

const PoolDetails: React.FC = () => {
  const dispatch = useDispatch();

  const { walletConnected, userAddress, kycVerified } = useSelector(
    (state: any) => state.user
  );
  const { id } = useParams<any>();
  useScrollToTop();
  const history = useHistory();
  const [decimals, setDecimals] = useState<any>(0);
  const [symbol, setSymbol] = useState<any>("");
  const [bnbPrice, setBnbPrice] = useState(0);
  const [gameDetail, setGameDetail] = useState<any>();
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState("");
  const [delayVal, setDelayVal] = useState(0);
  const [costVal, setCostVal] = useState(0);
  const [startedStatus, setStartedStatus] = useState<StartedProps>();
  const [tierInfo, setTierInfo] = useState<any>(0);
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [tokenVarious, setTokenVarious] = useState(0);
  const [depositAmount, setDepositAmount] = useState('0');
  const [tokenLeft, setTokenLeft] = useState('0');

  useEffect(() => {
    if (walletConnected && kycVerified !== "VERIFIED") {
      toast("Please complete your KYC to perform this action!", {
        type: toast.TYPE.WARNING,
      });
      history.push("/account/kyc");
    }
  }, [walletConnected, kycVerified]);

  const getData = async () => {
    const token = new wallet.web3.eth.Contract(
      userTokenAbi.abi,
      gameDetail?.poolInfo?.tokenAddress
    );
    try {
      const decimalsValue = await token.methods.decimals().call();
      const symbolValue = await token.methods.symbol().call();
      setDecimals(Number(decimalsValue));
      setSymbol(symbolValue);
    } catch (err) {
      alert("Error: fetching Pool details. Try again.");
    }
    const bnbPrice = await getBnbPrice();
    setBnbPrice(bnbPrice);
  };

  const findGameById = async () => {
    const { error, result } = await Game.findGameById(+id);
    if (!error && result) {
      setGameDetail(result);
    }
  };

  const getContractInternalValues = async () => {
    if (gameDetail.poolInfo?.poolAddress) {
      const leftTokens = await getPoolContract(gameDetail.poolInfo?.poolAddress)
        .methods.tokensLeft()
        .call();
      setTokenLeft((leftTokens / Math.pow(10, gameDetail.poolInfo?.tokenDecimals)).toFixed(0));
      if (gameDetail.poolInfo?.tokenVarious === 0) {
        const balance = await wallet.web3.eth.getBalance(
          gameDetail.poolInfo?.poolAddress
        );
        setDepositAmount(
          withThreeDecimals(+balance / Math.pow(10, gameDetail.poolInfo?.tokenDecimals))
        );
      } else if (gameDetail.poolInfo?.tokenVarious === 1) {
        const balance = await getUsdtContract()
          .methods.balanceOf(gameDetail.poolInfo?.poolAddress)
          .call();
          setDepositAmount(
            withThreeDecimals(balance / Math.pow(10, gameDetail.poolInfo?.tokenDecimals))
          );
        } else if (gameDetail.poolInfo?.tokenVarious === 2) {
        const balance = await getUsdcContract()
          .methods.balanceOf(userAddress)
          .call();
        setDepositAmount(
          withThreeDecimals(balance / Math.pow(10, gameDetail.poolInfo?.tokenDecimals))
        );
      } else if (gameDetail.poolInfo?.tokenVarious === 4) {
        const balance = await getDaiContract()
          .methods.balanceOf(userAddress)
          .call();
        setDepositAmount(
          withThreeDecimals(balance / Math.pow(10, gameDetail.poolInfo?.tokenDecimals))
        );
      }
    }
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleSubmit = async () => {
    dispatch(setLoading(true));
    if (gameDetail && startedStatus === 1 && delayVal) {
      if (gameDetail.poolInfo?.min1 && delayVal < gameDetail.poolInfo?.min1) {
        toast(`Please input amount more than ${gameDetail.poolInfo?.min1}`, {
          type: toast.TYPE.WARNING,
        });
        dispatch(setLoading(false));
        return;
      }
      if (!isWhitelisted) {
        toast(`You are not whitelisted to join sale`, {
          type: toast.TYPE.WARNING,
        });
        dispatch(setLoading(false));
        return;
      }
      if (tierInfo === 1) {
        if (gameDetail.poolInfo?.max1 && delayVal > gameDetail.poolInfo?.max1) {
          toast(`Please input amount less than ${gameDetail.poolInfo?.max1}`, {
            type: toast.TYPE.WARNING,
          });
          dispatch(setLoading(false));
          return;
        }
      } else if (tierInfo === 2) {
        if (gameDetail.poolInfo?.max2 && delayVal > gameDetail.poolInfo?.max2) {
          toast(`Please input amount less than ${gameDetail.poolInfo?.max2}`, {
            type: toast.TYPE.WARNING,
          });
          dispatch(setLoading(false));
          return;
        }
      } else if (tierInfo === 3) {
        if (gameDetail.poolInfo?.max3 && delayVal > gameDetail.poolInfo?.max3) {
          toast(`Please input amount less than ${gameDetail.poolInfo?.max3}`, {
            type: toast.TYPE.WARNING,
          });
          dispatch(setLoading(false));
          return;
        }
      } else if (tierInfo === 4) {
        if (gameDetail.poolInfo?.max4 && delayVal > gameDetail.poolInfo?.max4) {
          toast(`Please input amount less than ${gameDetail.poolInfo?.max4}`, {
            type: toast.TYPE.WARNING,
          });
          dispatch(setLoading(false));
          return;
        }
      } else if (tierInfo === 5) {
        if (gameDetail.poolInfo?.max5 && delayVal > gameDetail.poolInfo?.max5) {
          toast(`Please input amount less than ${gameDetail.poolInfo?.max5}`, {
            type: toast.TYPE.WARNING,
          });
          dispatch(setLoading(false));
          return;
        }
      }

      if (tokenVarious) {
        let token = await setStakeToken(tokenVarious);
        let calcAmount = ethers.utils.parseUnits(
          amount.toString(),
          gameDetail.poolInfo?.tokenDecimals
        );

        let approveResult = true;

        if (tokenVarious > 0) {
          approveResult = await token.methods
            .approve(
              gameDetail?.poolInfo?.poolAddress,
              setStakeAmount(
                TokenType[gameDetail.poolInfo.tokenVarious],
                costVal.toString()
              )
            )
            .send({ from: userAddress })
            .on("transactionHash", (hash: string) => {
              console.log("transaction hash ", hash);
            })
            .on("receipt", (receipt: any) => {
              toast("Successfully!", { type: toast.TYPE.SUCCESS });
              dispatch(setLoading(false));
            })
            .on("error", (error: any) => {
              dispatch(setLoading(false));
              toast(
                "Transaction has failed, please contact admin to get support!",
                { type: toast.TYPE.WARNING }
              );
            });
        }
        if (approveResult) {
          await getPoolContract(gameDetail?.poolInfo?.poolAddress)
            .methods.swap(calcAmount.toString())
            .send({ from: userAddress })
            .on("transactionHash", (hash: string) => {
              console.log("transaction hash ", hash);
            })
            .on("receipt", (receipt: any) => {
              toast("Successfully!", { type: toast.TYPE.SUCCESS });
              dispatch(setLoading(false));
              closeModal();
            })
            .on("error", (error: any) => {
              dispatch(setLoading(false));
              closeModal();
              toast(
                "Transaction has failed, please contact admin to get support!",
                { type: toast.TYPE.WARNING }
              );
            });
        }

        getContractInternalValues();
      }
    }
  };

  const checkWhitelisted = async () => {
    if (gameDetail.poolInfo.poolAddress) {
      const isWhitelisted = await getPoolContract(
        gameDetail?.poolInfo?.poolAddress
      )
        .methods.isWhitelisted(userAddress)
        .call();
      setIsWhitelisted(isWhitelisted);
      if (isWhitelisted) {
        const tier = await getPoolContract(gameDetail?.poolInfo?.poolAddress)
          .methods.getUserTier(userAddress)
          .call();

        setTierInfo(tier);
      }
    }
  };

  const getBalance = async () => {
    if (gameDetail && delayVal) {
      const poolAddress = gameDetail?.poolInfo?.poolAddress;
      if (tokenVarious) {
        try {
          let calcDelayAmount = await setStakeTokenAmount(
            delayVal.toString(),
            tokenVarious
          );
          const costVal = await getPoolContract(poolAddress)
            .methods.cost(calcDelayAmount)
            .call();
          const costFeeVal = await getPoolContract(poolAddress)
            .methods.costFee(calcDelayAmount)
            .call();
          if (costVal && costFeeVal && tokenVarious) {
            let calcCostVal = await setTokenFromAmount(
              costVal.toString(),
              tokenVarious
            );
            let calcCostFeeVal = await setTokenFromAmount(
              costFeeVal.toString(),
              tokenVarious
            );
            if (calcCostVal && calcCostFeeVal) {
              let calcCostValue =
                +calcCostVal > 0 ? parseFloat(calcCostVal) : 0;
              // let calcCostFeeValue =
              //   +calcCostFeeVal > 0 ? parseFloat(calcCostFeeVal) : 0;
              // const totalCostValue = calcCostValue + calcCostFeeValue;
              setCostVal(calcCostValue);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const checkStarted = () => {
    if (gameDetail && gameDetail.poolInfo) {
      const currentDate = new Date().valueOf();

      const startDate = new Date(
        gameDetail.poolInfo.startTime || ("" as string)
      ).valueOf();
      const endDate = new Date(
        gameDetail.poolInfo.endTime || ("" as string)
      ).valueOf();
      if (+currentDate < +startDate) {
        setStartedStatus(0);
      } else if (+currentDate > +startDate && +currentDate < +endDate) {
        setStartedStatus(1);
      } else if (+currentDate > +endDate) {
        setStartedStatus(2);
        checkProjectStatus();
      }
    }
  };

  const checkProjectStatus = async () => {
    if (!gameDetail.poolInfo.type) {
      try {
        const isSuccess = await getPoolContract(gameDetail.poolInfo.poolAddress)
          .methods.minimumRaiseAchieved()
          .call();
        setIsSuccess(isSuccess);
      } catch (e) {
        setIsSuccess(false);
      }
    } else {
      setIsSuccess(true);
    }
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setDelayVal(parseFloat(amount)), 500);
    return () => clearTimeout(timeOutId);
  }, [amount]);

  useEffect(() => {
    if (walletConnected) {
      findGameById();
    }
  }, [walletConnected, id]);

  useEffect(() => {
    if (gameDetail?.poolInfo) {
      checkStarted();
      checkWhitelisted();

      if (gameDetail?.poolInfo?.tokenAddress) {
        getData();
      }

      if (gameDetail.poolInfo.tokenVarious) {
        setTokenVarious(gameDetail.poolInfo.tokenVarious);
      }

      getContractInternalValues();
    }
  }, [walletConnected, gameDetail]);

  useEffect(() => {
    if (delayVal) getBalance();
  }, [delayVal]);

  return (
    <PageContainer className="container">
      <Container>
        {true ? (
          <>
            <PoolInfoEl>
              <PoolStatus
                gameDetail={gameDetail && gameDetail}
                pool={gameDetail && gameDetail.poolInfo}
                decimals={decimals}
                bnbPrice={bnbPrice}
                symbol={symbol}
                isSuccess={isSuccess}
                tokenLeft={tokenLeft}
              />
            </PoolInfoEl>
            <PoolDetail>
              <PoolDetPoolDetailsNew
                gameDetail={gameDetail}
                startedStatus={startedStatus}
                depositAmount={depositAmount}
                pool={gameDetail && gameDetail.poolInfo}
                isSuccess={isSuccess}
                show={show}
                setShow={setShow}
              />
            </PoolDetail>
          </>
        ) : null}
      </Container>
      <Modal
        show={show}
        width="603px"
        className="modal-container pb-5"
        closeModal={closeModal}
      >
        <div className="modal-title">
          <p>{startedStatus === 1 && "Purchase Amount"}</p>
        </div>
        <div className="modal-body">
          <RaiseContainer className="m-0 mb-3">
            <HeaderPrimary className="text-left">Rate</HeaderPrimary>
            <AmountRaised>
              1 {TokenType[gameDetail?.poolInfo?.tokenVarious || 0]} ={" "}
              {gameDetail && gameDetail?.poolInfo
                ? +gameDetail?.poolInfo?.tokenPrice
                : "TBA"}{" "}
              {symbol}
            </AmountRaised>
          </RaiseContainer>
          <div className="row check_Title">
            <div className="col">
              <p>Token amount</p>
            </div>
            <div className="col">
              <p>{TokenType[gameDetail?.poolInfo?.tokenVarious || 0]} Amount</p>
            </div>
          </div>
          <div className="row check_amount">
            <div className="col">
              <input
                value={amount}
                placeholder={`${
                  TokenType[gameDetail?.poolInfo?.tokenVarious || 0]
                } Amount`}
                onChange={(event) => {
                  const inputValue = event.target.value.trim();
                  if (/^-?\d*\.?\d*$/.test(inputValue) || inputValue === "") {
                    setAmount(inputValue);
                  }
                }}
              />
            </div>
            <div className="col">
              <div className="network-info">
                <input
                  value={costVal && amount ? costVal : ""}
                  placeholder="Token Amount"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-center">
          <button onClick={() => handleSubmit()} className="btn-action-link">
            {startedStatus === 1 && "SWAP"}
          </button>
        </div>
      </Modal>
    </PageContainer>
  );
};
export default PoolDetails;
