/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorContainer, InputFieldSmall1 } from "../../style";
import { AllocationType, RoyaStaking } from "../../../../utils";
import React, { useEffect, useState } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import _ from "lodash";

interface Props {
  values: any;
  setIsChanged: (val: boolean) => void;
  handleAccessType: (type: string) => void;
  handleMinAllocationType: (type: string) => void;
  handleMaxAllocationType: (index: number, type: string) => void;
}

const PoolAllocation: React.FC<Props> = ({
  values,
  setIsChanged,
  handleMinAllocationType,
  handleMaxAllocationType,
}) => {
  const formikProps = useFormikContext();
  const [checkboxmin, setcheckboxmin] = useState(false);
  const [checkboxmax, setcheckboxmax] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const [needPrivate, setNeedPrivate] = useState(false);
  const [privateRatio, setPrivateRatio] = useState(0);

  const isCheckedFunc = (values: any): void => {
    let isChanged = false;
  
    const hasNonUnlimited = values.maxAllocationTypes.some((item: string) => item !== "unlimited");
    const hasNonDash = values.maxAllocations.some((item: string) => (item !== "-" && item !== ""));
    
    const isMinAllocationNotDefault = (values.minAllocation !== "1" && values.minAllocation !== "") && values.minAllocationType !== "unlimited";

    isChanged = (hasNonUnlimited && hasNonDash) || isMinAllocationNotDefault || privateRatio;
  
    setIsChanged(isChanged);
  };

  useEffect(() => {
    isCheckedFunc(values);

    setcheckboxmin(values.minAllocationType === AllocationType.limited);

    setcheckboxmax(
      values.maxAllocationTypes.map((x: any) => x === AllocationType.limited)
    );
    setNeedPrivate(values.isPrivate);
    setPrivateRatio(values.privateRatio);
  }, [values]);

  const handleChangemin = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setcheckboxmin(true);
      values.minAllocationType = AllocationType.limited;
      handleMinAllocationType(AllocationType.limited);
      formikProps.setFieldValue("minAllocation", "", true);
      setIsChanged(true);
    } else {
      setcheckboxmin(false);
      values.minAllocationType = AllocationType.unlimited;
      handleMinAllocationType(AllocationType.unlimited);
      formikProps.setFieldValue("minAllocation", "-", true);
      setIsChanged(true);
    }
  };

  const handleChangemax = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      setcheckboxmax(
        _.cloneDeep(checkboxmax.map((x, i) => (index === i ? true : x)))
      );
      values.maxAllocationTypes[index] = AllocationType.limited;
      handleMaxAllocationType(index, AllocationType.limited);
      formikProps.setFieldValue(`maxAllocations[${index}]`, "", true);
      setIsChanged(true);
    } else {
      setcheckboxmax(
        _.cloneDeep(checkboxmax.map((x, i) => (index === i ? false : x)))
      );
      values.maxAllocationTypes[index] = AllocationType.unlimited;
      handleMaxAllocationType(index, AllocationType.unlimited);
      formikProps.setFieldValue(`maxAllocations[${index}]`, "-", true);
      setIsChanged(true);
    }
  };

  const handleAccesType = (val: boolean) => {
    if (val) {
      formikProps.setFieldValue("isPrivate", true);
      setNeedPrivate(true);
      setIsChanged(true);
    } else {
      setNeedPrivate(false);
      formikProps.setFieldValue("isPrivate", false);
      setIsChanged(true);
    }
  };

  const handleRatio = (val: any) => {
    setPrivateRatio(val);
    formikProps.setFieldValue("privateRatio", val ? +val : 0);
  };

  return (
    <>
      <div className="pool-step-01">
        <div className="pool-name-box">
          <div className="allocation-container">
            <div>
              <span className="access-label">Min. Allocation per Wallet</span>
              <div className="allocation-grid01">
                <div className="step-form-control">
                  <InputFieldSmall1
                    className="submit-form-control"
                    name="minAllocation"
                    placeholder="Value"
                    disabled={!checkboxmin}
                  />
                </div>
                <div>
                  <div className="access-col tab">
                    <label
                      htmlFor="minAlloc"
                      className={`type-label ${!checkboxmin ? "checked" : ""}`}
                    >
                      Unlimited
                    </label>
                    <div className="access-switch">
                      <label className="switch">
                        <input
                          id="minAlloc"
                          type="checkbox"
                          checked={checkboxmin}
                          onChange={(e) => handleChangemin(e)}
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <label
                      htmlFor="minAlloc"
                      className={`type-label ${checkboxmin ? "checked" : ""}`}
                    >
                      Limited
                    </label>
                  </div>
                </div>
              </div>
              <ErrorContainer>
                <ErrorMessage name="minAllocation" />
              </ErrorContainer>
            </div>
            {[
              RoyaStaking.none,
              RoyaStaking.settler,
              RoyaStaking.merchant,
              RoyaStaking.knight,
              RoyaStaking.archon,
              RoyaStaking.monarch,
            ].map((x, index) => (
              <div key={index}>
                <span className="access-label">
                  Max Allocation per Wallet &nbsp; ({x.title})
                </span>
                <div className="allocation-grid01">
                  <div className="step-form-control">
                    <InputFieldSmall1
                      className="submit-form-control"
                      name={`maxAllocations[${index}]`}
                      placeholder="Value"
                      disabled={!checkboxmax[index]}
                    />
                  </div>
                  <div>
                    <div className="access-col tab">
                      <label
                        htmlFor={`maxAlloc[${index}]`}
                        className={`type-label ${
                          !checkboxmax[index] ? "checked" : ""
                        }`}
                      >
                        Unlimited
                      </label>
                      <div className="access-switch">
                        <label className="switch">
                          <input
                            id={`maxAlloc[${index}]`}
                            type="checkbox"
                            checked={checkboxmax[index]}
                            onChange={(e) => handleChangemax(index, e)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <label
                        htmlFor={`maxAlloc[${index}]`}
                        className={`type-label ${
                          checkboxmax[index] ? "checked" : ""
                        }`}
                      >
                        Limited
                      </label>
                    </div>
                  </div>
                  <ErrorContainer>
                    <ErrorMessage name="maxAllocation" />
                  </ErrorContainer>
                </div>
              </div>
            ))}
            <div className="access-grid">
              <span className="access-label">Access Type</span>
              <div className="access-col">
                <div className="time-lock">
                  <label htmlFor="myCheck" className="">
                    <input
                      type="checkbox"
                      id="myCheck"
                      checked={needPrivate}
                      onChange={(e) => handleAccesType(e.target.checked)}
                    />
                    <span className="input"></span>
                    Is private allocation required?
                  </label>
                </div>
              </div>
            </div>
            {needPrivate && (
              <div className="allocation-grid02">
                <div className="step-form-control">
                  <label>Distribution Ratio</label>
                  <div className="ratio-control">
                    <input
                      type="number"
                      placeholder=""
                      value={privateRatio}
                      onChange={(e) => handleRatio(+e.currentTarget.value)}
                      className="submit-form-control"
                    />
                    <span className="ratio-label">Private</span>
                  </div>
                </div>
                <div className="step-form-control">
                  <label>&nbsp;</label>
                  <div className="ratio-control">
                    <input
                      type="text"
                      placeholder=""
                      disabled
                      value={100 - privateRatio}
                      className="submit-form-control"
                    />
                    <span className="ratio-label">Ticker TBD</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PoolAllocation;
