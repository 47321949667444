import styled from "styled-components";
import { PoolDetailTab } from "../../../../utils";

export const Tab = styled.div<TabProps>`
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  padding-bottom: 9px;
  color: ${(props) => (props.active ? "#2CFEE1" : "#7682a5")};
  font-weight: ${(props) => (props.active ? "700" : "400")};
  cursor: pointer;
  transition: 300ms all;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(162, 184, 219, 0.15);
  position: relative;

  ${Tab}:first-child {
    width: 97px;
    margin-right: 21px;
  }

  ${Tab}:last-child {
    width: 145px;
  }
`;

interface TabProps {
  active: boolean;
}

interface ActiveLineProps {
  activeTab: number;
}

export const AciveLine = styled.div<ActiveLineProps>`
  position: absolute;
  height: 1px;
  width: ${(props) =>
    props.activeTab === PoolDetailTab.detail ? "97px" : "145px"};
  background-color: #2cfee1;
  bottom: -1px;
  left: 0;
  transform: ${(props) =>
    props.activeTab === PoolDetailTab.detail
      ? "translateX(0)"
      : "translateX(118px)"};
  transition: 300ms all;
`;
