import styled from 'styled-components';
import { PrimaryButton, Theme, Color } from '../../utils';

export const PoolListItem = styled.div`
  max-width: 340px;
  background: ${Theme.headerBackground};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  margin-top: 30px;
  padding: 20px 30px 40px 30px;
  @media only screen and (max-width: 500px) {
    padding: 14px 20px 30px;
    max-width: 90%;
  }
`;

export const PollListItemTop = styled.div`
  display: flex;
`;

export const PollItemIcon = styled.div`
  margin-top: 10px;
  width: 70px;
  height: 70px;
  img {
    width: 100%;
    border-radius: 50%;
    border: 1px solid #18355b;
  }
`;

export const PoolItemNameContainer = styled.div`
  margin-left: 15px;
`;

export const PoolItemNameText = styled.div`
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #ffffff;
`;

export const TimeContainer = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
`;

export const PoolTime = styled.div`
  width: 110px;
  height: 26px;
  display: flex;
  align-items: center;
  background: rgba(44, 254, 225, 0.2);
  border-radius: 50px;
  justify-content: center;
`;

export const PoolTimeDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #2cfee1;
`;

export const PoolTimeText = styled.div`
  margin-left: 7px;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #2cfee1;
`;

export const PoolType = styled.div`
  background: #2cfee1;
  border-radius: 50px;
  margin-left: 11px;
  width: 78px;
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #010c2a;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PoolMidText = styled.div`
  margin-top: 20px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #ffffff;
  vertical-align: text-bottom;
`;

export const PoolFundText = styled.div`
  margin-top: 7px;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #2cfee1;
`;

export const AllocationContainer = styled.div`
  margin-top: 17px;
  display: flex;
`;

export const AllocationItem = styled.div``;

export const AllocationHeader = styled.div`
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #ffffff;
`;

export const AllocationLine = styled.div`
  width: 1px;
  height: 55px;
  background-color: #214666;
  margin: 0 40px;
`;

export const AllocationValue = styled.div`
  margin-top: 16px;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 0.1px;
  text-align: center;
  color: #77d3f1;
`;

export const RegisterText = styled.div`
  margin-top: 30px;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #ffffff;
`;

export const LoaderContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 2px;
  background: rgba(162, 184, 219, 0.15);
  border-radius: 5px;
`;

export const LoaderLine = styled.div`
  width: 50%;
  height: 100%;
  background: #2dd2ff;
  border-radius: 5px 0px 0px 5px;
`;

export const BtnContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ViewDetailsBtn = styled(PrimaryButton)`
  width: 180px;
  height: 44px;
  background: linear-gradient(180deg, #25d9fd 0%, #2cfee1 100%);
  box-shadow: 0px 0px 20px rgba(45, 210, 255, 0.6);
  border-radius: 30px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

export const Icon = styled.a`
  margin-left: 10px;
  fill: ${Color.lightBluePrimary};
`;

export const IconsContainer = styled.div`
  display: flex;
`;
