import { FETCH_BLOGS_SUCCESS } from "../actions/blogs.action";

const initialState = {
  blogs: []
}

const blogsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_BLOGS_SUCCESS:
      return {
        ...state,
        blogs: action.blogs
      }

    default:
      return state;
  }
}

export default blogsReducer;
