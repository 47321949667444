import gql from 'graphql-tag';

export const POOL_BY_ID = gql`
  query findPoolInfoById($id: Int!) {
    findPoolInfoById(id: $id) {
      type
      tokenAddress
      tokenDecimals
      poolAddress
      tokenPrice
      totalTokens
      minRaise
      startTime
      endTime
      enableLock
      min1
      max1
      max2
      max3
      max4
      max5
      max6
      isPrivate
      ratio
      currentStep
    }
  }
`;

export const UPSERT_POOL = gql`
  mutation upsertPoolInfo($data: AddPoolInfo!) {
    upsertPoolInfo(data: $data) {
      id
      gameId
      poolType
      name
      tokenAddress
      tokenDecimals
      tokenSymbol
      tokenPrice
      tokenVarious
      totalTokens
      totalRaise
      minRaise
      startTime
      endTime
      enableLock
      min1
      max1
      max2
      max3
      max4
      max5
      max6
      isPrivate
      ratio
      currentStep
    }
  }
`;

