
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Header, Footer, Loader } from "./components";
import {
  LandingZone,
  StakingLots,
  TermsAndConditions,
  SubmitGame,
  GameDetails,
  PoolRegistration,
  Dashboard,
  Nfts,
  AllProjects,
  EditGame,
  EditPool,
  PageNotFound,
} from "./modules";
import { Paths } from "./utils";
import { Account } from "./modules/account";
import PoolDetails from "./modules/pool-details";

import RoyaReserve from "./modules/roya-reserve";

const App = () => {
  return (
    <div className="weeweepad-container">
      <Router>
        <Header />
        <div className="inner-wrap">
          <Switch>
            <Route exact path={Paths.landingZone} component={LandingZone} />
            <Route exact path={Paths.stakingLots} component={StakingLots} />
            <Route exact path={Paths.terms} component={TermsAndConditions} />
            <Route exact path={Paths.submitGame} component={SubmitGame} />
            <Route
              exact
              path={`${Paths.gameDetails}/:id`}
              component={GameDetails}
            />
            <Route
              exact
              path={`${Paths.gameDetails}/:id/edit`}
              component={EditGame}
            />
            <Route
              exact
              path={`${Paths.poolregistration}/:id`}
              component={PoolRegistration}
            />
            <Route exact path={Paths.dashboard} component={Dashboard} />
            <Route exact path={Paths.royaReserve} component={RoyaReserve} />
            <Route exact path={Paths.allProjects} component={AllProjects} />
            <Route
              exact
              path={`${Paths.poolDetails}/:id`}
              component={PoolDetails}
            />
            <Route exact path={`/:id${Paths.editPool}`} component={EditPool} />
            <Route path={Paths.account} component={Account} />
            <Route exact path={Paths.nfts} component={Nfts} />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <Footer />
        </div>
        <ToastContainer className="custom-toast-container" />
      </Router>
      <Loader />
    </div>
  );
};

export default App;
