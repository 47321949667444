/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { Paths, TokenType, getDaiContract, getPoolContract, getUsdcContract, getUsdtContract } from "../../utils";
import { Modal } from "../../components";
import {
  PlaceHolderImg,
  TelegramIcon,
  TwitterIcon,
} from "../../images";
import LoginModalContainer from "../../components/login-modal";
import { Game, GameRegisterInfo } from "../../core";

import "./styles.scss";
import { powToNumber, withFiveDecimals, withThreeDecimals } from "src/utils/number";
import wallet from "../../utils/wallet";

interface Params {
  id: string;
}

const GameDetails = () => {
  const { userAddress, walletConnected } = useSelector(
    (state: any) => state.user
  );
  const { id }: Params = useParams();

  const [isRegistered, setIsRegistered] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [showWallet, setshowWallet] = useState(false);
  const [whitelist, setWhitelist] = useState([]);
  const [gameDetail, setGameDetail] = useState<any>();
  const [totalRaise, setTotalRaise] = useState("");
  const [isWhitelisted, setWhitelisted] = useState(false);
  const [depositAmount, setDepositAmount] = useState('0');
  const [tokenLeft, setTokenLeft] = useState('0');

  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [walletConnected, userAddress]);
  useEffect(() => {
    if (walletConnected && gameDetail) {
      if (gameDetail.poolInfo && gameDetail.poolInfo.poolAddress) {
        getTokenAllocated();
        getWhitelistDetail();
        fetchWhiteListed();
        getContractInternalValues();
      }
    }
  }, [walletConnected, gameDetail]);

  const closeModal = () => {
    setshowWallet(false);
    setShowAlert(false);
  };

  const getGameDetail = async () => {
    const { error, result } = await Game.findGameById(+id);
    if (!error && result) {
      setGameDetail(result);
      // const parsedVideo = JSON.parse(result?.video || "[]");
      // if (result?.videoLink && !!parsedVideo?.length) {
      //   setIsSelectedVideo(result?.videoLink);
      //   setIsVideoList([result?.videoLink, ...parsedVideo]);
      // } else {
      //   if (result?.videoLink) {
      //     setIsVideoList([result?.videoLink]);
      //     setIsSelectedVideo(result?.videoLink);
      //   } else if (!!parsedVideo?.length) {
      //     setIsVideoList([...parsedVideo]);
      //     setIsSelectedVideo(parsedVideo[0]);
      //   }
      // }
      const poolInfo = result.poolInfo;
      if (poolInfo) {
        setTotalRaise(
          (
            +powToNumber(poolInfo.totalTokens) /
            Math.pow(10, poolInfo.tokenDecimals) /
            +poolInfo.tokenPrice
          ).toFixed(4)
        );
      }
    }
  };

  const getWhitelistDetail = async () => {
    const whitelist = await getPoolContract(gameDetail!.poolInfo?.poolAddress)
      .methods.getWhitelistedAddresses()
      .call();
    setWhitelist(whitelist);
  };

  const fetchWhiteListed = async () => {
    if (gameDetail!.poolInfo?.poolAddress) {
      const whitelisted = await getPoolContract(
        gameDetail!.poolInfo?.poolAddress
      )
        .methods.isWhitelisted(userAddress)
        .call();
      setWhitelisted(whitelisted);
    }
  };

  const getTokenAllocated = async () => {
    await getPoolContract(gameDetail!.poolInfo?.poolAddress)
      .methods.tokensLeft()
      .call();
  };

  const checkIsRegistered = async () => {
    const result = await GameRegisterInfo.checkIsRegistered(+id);
    if (!result.error) {
      setIsRegistered(result.result);
    }
  };

  useEffect(() => {
    if (id) {
      getGameDetail();
      checkIsRegistered();
    }
  }, [id]);

  const goToRegistrationPage = () => {
    if (walletConnected) {
      if (userAddress === gameDetail?.user?.walletAddress) {
        history.push(`${Paths.gameDetails}/${id}/edit`);
      } else {
        history.push(`${Paths.poolregistration}/${id}`);
      }
    } else {
      setshowWallet(true);
    }
  };

  const handleJoinSale = () => {
    if (walletConnected) {
      history.push(`${Paths.poolDetails}/${id}`);
    } else {
      setshowWallet(true);
    }
  };

  const getContractInternalValues = async () => {
    if (gameDetail.poolInfo?.poolAddress) {
      const leftTokens = await getPoolContract(gameDetail.poolInfo?.poolAddress)
        .methods.tokensLeft()
        .call();
      setTokenLeft((leftTokens / Math.pow(10, gameDetail.poolInfo?.tokenDecimals)).toFixed(0));
      if (gameDetail.poolInfo?.tokenVarious === 0) {
        const balance = await wallet.web3.eth.getBalance(
          gameDetail.poolInfo?.poolAddress
        );
        setDepositAmount(
          withThreeDecimals(+balance / Math.pow(10, gameDetail.poolInfo?.tokenDecimals))
        );
      } else if (gameDetail.poolInfo?.tokenVarious === 1) {
        const balance = await getUsdtContract()
          .methods.balanceOf(gameDetail.poolInfo?.poolAddress)
          .call();
          setDepositAmount(
            withThreeDecimals(balance / Math.pow(10, gameDetail.poolInfo?.tokenDecimals))
          );
        } else if (gameDetail.poolInfo?.tokenVarious === 2) {
        const balance = await getUsdcContract()
          .methods.balanceOf(userAddress)
          .call();
        setDepositAmount(
          withThreeDecimals(balance / Math.pow(10, gameDetail.poolInfo?.tokenDecimals))
        );
      } else if (gameDetail.poolInfo?.tokenVarious === 4) {
        const balance = await getDaiContract()
          .methods.balanceOf(userAddress)
          .call();
        setDepositAmount(
          withThreeDecimals(balance / Math.pow(10, gameDetail.poolInfo?.tokenDecimals))
        );
      }
    }
  };

  return (
    <div className="game-details">
      <Modal show={showWallet} closeModal={closeModal}>
        <LoginModalContainer show={showWallet} closeModal={closeModal} />
      </Modal>
      {gameDetail && 
        <div className="details-row">
          {/* <div className="new-page-frame">
            <img src={TopbackCover01Img.default} alt="" />
          </div>
          <div className="new-page-frame1">
            <img src={TopbackCover02Img.default} alt="" />
          </div>
          <div className="new-page-frame2">
            <img src={TopbackCover03Img.default} alt="" />
          </div> */}
          <div className="container">
            <div className="game-detail-info">
              <div className="game-apply-img">
                <img
                  src={
                    gameDetail.logo
                      ? gameDetail.logo
                      : gameDetail?.tokenURL
                      ? gameDetail?.tokenURL
                      : PlaceHolderImg.default
                  }
                  alt=""
                />
              </div>
              <div className="game-apply-content">
                <h4>{gameDetail?.name}</h4>
                <span className="game-dark-info mt-1 mb-2">
                  {gameDetail?.poolInfo?.tokenSymbol || "XXXX"}/
                  {gameDetail?.poolInfo?.tokenVarious
                    ? TokenType[gameDetail?.poolInfo?.tokenVarious]
                    : "XXXX"}
                </span>
                <div className="game-submit-col-mobile">
                  <button
                    disabled={isRegistered}
                    onClick={() => goToRegistrationPage()}
                    className={`btn-action-link ${
                      isRegistered && "noncursor-pointer"
                    }`}
                  >
                    {userAddress === gameDetail.user?.walletAddress
                      ? "Edit"
                      : isRegistered
                      ? "Applied"
                      : "Apply Now"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="New-game-left-back-cover">
            <img src={LeftbackCoverImg.default} alt="" />
          </div>
          <div className="New-game-right-back-cover">
            <img src={RightbackCoverImg.default} alt="" />
          </div> */}

          <div className="game-details-container">
            <div className="container">
              <div className="game-details-grid">
                <div className="main-width">
                  {/* <div className="border-box-wrap about-project-box">
                    <div className="project-video-wrap">
                      <ReactPlayer
                        key={isSelectedVideo + "react-player"}
                        url={isSelectedVideo}
                        className="react-player"
                        controls={true}
                      />
                    </div>
                    {!!isVideoList?.length && (
                      <div className="video-preview">
                        {isVideoList?.map((videoUrl, index) => (
                          <div
                            key={videoUrl + index}
                            onClick={() => setIsSelectedVideo(videoUrl)}
                            className="cursor-pointer"
                          >
                            <ReactPlayer
                              url={videoUrl}
                              className="react-player me-3 pe-none"
                              controls={false}
                              key={videoUrl + index}
                              height={"80px"}
                              width={"150px"}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div> */}
                  <div className="about-project-box">
                    <div className="about-top-row1 text-center">
                      <h3>About Project</h3>
                      <h3>{gameDetail?.name}</h3>
                      <div className="description">
                        {gameDetail.description}
                      </div>
                      <div className="social-links">
                        <a
                          rel="noreferrer"
                          href={"https://t.me/" + gameDetail.telegram}
                          target="_blank"
                        >
                          <img src={TwitterIcon.default} alt="Telegram" />
                        </a>
                        <a
                          rel="noreferrer"
                          href={"https://twitter.com/" + gameDetail.twitter}
                          target="_blank"
                        >
                          <img src={TelegramIcon.default} alt="Twitter" />
                        </a>
                      </div>
                    </div>
                    {/* <div className="project-info">
                      <ReactQuill
                        value={gameDetail?.fullDescription}
                        readOnly={true}
                        theme={"bubble"}
                      />
                    </div> */}
                    {/* <div className="pitchDecklink-wrapper mt-1">
                      <img src={PDFIcon.default} />
                      <a
                        rel="noreferrer"
                        href={gameDetail?.pitchDeckLink}
                        target="_blank"
                      >
                        {gameDetail?.pitchDeckLink.split("/")[4]}
                      </a>
                    </div> */}

                    {/* Pit1 */}
                    <div className="about-top-row1 text-center mt-2">
                      <h3>Pitchdeck</h3>
                      <a className="description" href={gameDetail?.pitchDeckLink} target="_blank" rel="noreferrer">
                        {gameDetail?.pitchDeckLink}
                      </a>
                    </div>
                    <div className="border-box-wrap mt-3">
                      <div className="about-winnning-row">
                        <h3>Whitelist addresses</h3>
                        <div className="table-responsive">
                          <table className="table whitelist-table">
                            <thead className="whitelist-table_thead">
                              <tr>
                                <th>Address</th>
                              </tr>
                            </thead>
                            <tbody>
                              {whitelist.length > 0 &&
                                whitelist.map((address, index) => (
                                  <tr key={index}>
                                    <td className="success-text-color">{address}</td>
                                  </tr>
                                ))}
                              {whitelist.length === 0 && (
                                <tr className="loading-container">
                                  <th>
                                    No address yet
                                  </th>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="details-sidebar">
                  <div className="border-box-wrap">
                    <div className="details-sidebar-box">
                      <div className="sidebar-original-info">
                        <h3>
                          {gameDetail?.poolInfo?.tokenSymbol
                            ? gameDetail.poolInfo.tokenSymbol
                            : "XXXXXX"}{" "}
                          Pool
                        </h3>
                      </div>
                      <div className="sidebar-details-02">
                        {gameDetail.poolInfo ? (
                          <>
                            <div className="details-info-raised">
                              <div className="row mb-3">
                                <div className="col text-start">
                                  Total Raise
                                </div>
                                <div className="col text-end">
                                  {totalRaise}{" "}
                                  {TokenType[gameDetail.poolInfo.tokenVarious]}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col text-start">
                                  Fund Raise
                                </div>
                                <div className="col text-end">
                                  {depositAmount}{" "}
                                  {TokenType[gameDetail.poolInfo.tokenVarious]}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col text-start">
                                  Total Tokens
                                </div>
                                <div className="col text-end">
                                  {gameDetail.poolInfo.totalTokens /
                                    Math.pow(
                                      10,
                                      gameDetail.poolInfo.tokenDecimals
                                    )}{" "}
                                  {gameDetail.poolInfo.tokenSymbol}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col text-start">
                                  Token Sales
                                </div>
                                <div className="col text-end">
                                  {tokenLeft}{" "}
                                  {gameDetail.poolInfo.tokenSymbol}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col text-start">
                                  Participants
                                </div>
                                <div className="col text-end">
                                  {gameDetail.totalSafelist}
                                </div>
                              </div>
                              <div className="row mb-3">
                                <div className="col text-start">
                                  IPO Token Price
                                </div>
                                <div className="col text-end">
                                  {withFiveDecimals(
                                    1 / parseInt(gameDetail.poolInfo.tokenPrice)
                                  )}{" "}
                                  {TokenType[gameDetail.poolInfo.tokenVarious]}
                                </div>
                              </div>
                            </div>
                            <div className="New-details-info-percent">
                              <div
                                className="percent-count"
                                style={{
                                  width:
                                    (parseInt(depositAmount) /
                                      parseInt(totalRaise)) *
                                      100 +
                                    "%",
                                }}
                              ></div>
                            </div>
                            <div className="New-submit-col1">
                              {userAddress !==
                                gameDetail.user?.walletAddress && (
                                <button
                                  disabled={!isWhitelisted}
                                  onClick={handleJoinSale}
                                  className={`btn-action-link1 ${
                                    isWhitelisted
                                      ? "cursor-pointer"
                                      : "noncursor-pointer"
                                  }`}
                                >
                                  JOIN SALE
                                </button>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="New-game-dark-coin">
                            <h4>Pool Details:</h4>
                            <p>Coming Soon</p>
                          </div>
                        )}
                      </div>
                      <div className="sidebar-details-03">
                        {gameDetail.poolInfo ? (
                          <>
                            <span>
                              From&nbsp;
                              {moment(gameDetail.poolInfo.startTime).format(
                                "DD-MM-YYYY hh:mm"
                              )}{" "}
                              To &nbsp;
                              {moment(gameDetail.poolInfo.endTime).format(
                                "DD-MM-YYYY hh:mm"
                              )}{" "}
                            </span>
                          </>
                        ) : (
                          <div className="New-game-dark-coin">
                            <span>IPO info: Coming Soon</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {!gameDetail.poolInfo &&
                    gameDetail.user?.walletAddress.toLowerCase() ===
                      userAddress.toLowerCase() && (
                      <div className="new-geme-details-sidebar-box">
                        <div className="New-game-dark-coin">
                          <h5>SET POOL</h5>
                        </div>
                        <div className="sidebar-details-03">
                          <span className="details-info-raised">
                            Set pool to start funding your project
                          </span>
                          <div className="New-submit-col1">
                            <Link
                              to={`/account/my-project`}
                              className="btn-action-link1"
                            >
                              SET NOW
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <Modal show={ShowAlert} closeModal={closeModal}>
        <div className="register-innerbg">
          <div className="successfully">
            <h4>
              You have not registered or your address is not whitelisted for
              this pool
            </h4>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GameDetails;
