/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoginModalContainer from "../../../src/components/login-modal";
import { TierType, TokenType } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { CheckGreenIcon } from "../../images";
import { Modal } from "../../components";
import { Paths } from "../../utils";
import { Game, GameRegisterInfo } from "../../core";
import { getUserTotalLots } from "src/logic/actions";
import { powToNumber } from "src/utils/number";
import { toast } from "react-toastify";
interface Params {
  id: string;
}

const PoolRegistration = () => {
  const dispatch = useDispatch();
  const { id } = useParams<Params>();
  const [useremail, setuseremail] = useState("");
  const [username, setusername] = useState("");
  const [twiterhandle, settwiterhandle] = useState("");
  const [remarks, setremarks] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [termscondition, settermscondition] = useState(false);
  const [updates, setupdates] = useState(false);
  const [receivealert, setreceivealert] = useState(true);
  const [submitname, setsubmitname] = useState(false);
  const [submitemail, setsubmitemail] = useState(false);
  const [gotoFeed, setgotoFeed] = useState(false);
  const [retweet, setretweet] = useState(false);
  const [join, setjoin] = useState(false);
  const [tier, setTier] = useState(-1);
  const [gameDetail, setGameDetail] = useState<any>();
  const [totalRaise, setTotalRaise] = useState("0");
  const [tierlist, setTierlist] = useState<Array<any>>([]);

  const {
    totalKroyaLot,
    totalAroyaLot,
    totalSroyaLot,
    totalMroyaLot,
    totalMoroyaLot,
    totalSNFTLot,
    totalMNFTLot,
    totalKNFTLot,
    totalANFTLot,
    totalMoNFTLot,
  } = useSelector((state: any) => state.stakingLots);

  const updategotofeed = () => {
    if (gotoFeed === false) {
      setgotoFeed(true);
      window.open(`https://twitter.com/${gameDetail?.twitter}`, "_blank");
    }
  };
  const updateretweet = () => {
    if (retweet === false) {
      setretweet(true);
      window.open(`https://twitter.com/${gameDetail?.twitter}`, "_blank");
    }
  };

  const updatejoin = () => {
    if (join === false) {
      setjoin(true);
      window.open(`https://twitter.com/${gameDetail?.twitter}`, "_blank");
    }
  };

  const { userAddress, walletConnected, kycVerified } = useSelector(
    (state: any) => state.user
  );
  const history = useHistory();
  const [loginshow, setloginShow] = useState(false);

  useEffect(() => {
    if (walletConnected && kycVerified !== "VERIFIED") {
      toast("Please complete your KYC to perform this action!", {
        type: toast.TYPE.WARNING,
      });
      history.push("/account/kyc");
    }
  }, [walletConnected, kycVerified]);

  const closeModal = () => {
    setShowMsg(false);
    // history.push(`${Paths.dashboard}`);
  };

  const getValuefortermsconditions = () => {
    if (termscondition === false) {
      settermscondition(true);
      setreceivealert(true);
    } else {
      settermscondition(false);
    }
  };
  const getValueforupdate = () => {
    if (updates === false) {
      setupdates(true);
    } else {
      setupdates(false);
    }
  };

  const getGameDetail = async () => {
    const { error, result } = await Game.findGameById(+id);
    if (!error && result) {
      setGameDetail(result);
      const poolInfo = result.poolInfo;
      if (poolInfo) {
        setTotalRaise(
          (
            +powToNumber(poolInfo.totalTokens) /
            Math.pow(10, poolInfo.tokenDecimals) /
            +poolInfo.tokenPrice
          ).toFixed(4)
        );
      }
    }
  };

  useEffect(() => {
    if (walletConnected && id) {
      getGameDetail();
      dispatch(getUserTotalLots(userAddress));
    }
  }, [walletConnected, id]);

  const updateTierList = async () => {
    const list = [];
    if (+totalSNFTLot + +totalSroyaLot > 0) {
      list.push({
        tokenId: TierType.settler,
        tokenQuantity: +totalSNFTLot + +totalSroyaLot,
      });
    }
    if (+totalMNFTLot + +totalMroyaLot > 0) {
      list.push({
        tokenId: TierType.merchant,
        tokenQuantity: +totalMNFTLot + +totalMroyaLot,
      });
    }
    if (+totalKNFTLot + +totalKroyaLot > 0) {
      list.push({
        tokenId: TierType.knight,
        tokenQuantity: +totalKNFTLot + +totalKroyaLot,
      });
    }
    if (+totalANFTLot + +totalAroyaLot > 0) {
      list.push({
        tokenId: TierType.archon,
        tokenQuantity: +totalANFTLot + +totalAroyaLot,
      });
    }
    if (+totalMoNFTLot + +totalMoroyaLot > 0) {
      list.push({
        tokenId: TierType.monarch,
        tokenQuantity: +totalMoNFTLot + +totalMoroyaLot,
      });
    }
    setTierlist(list);
    if (list.length > 0) {
      setTier(list[0].tokenId);
    }
  };

  useEffect(() => {
    updateTierList();
  }, [
    totalKroyaLot,
    totalAroyaLot,
    totalSroyaLot,
    totalMroyaLot,
    totalMoroyaLot,
    totalSNFTLot,
    totalMNFTLot,
    totalKNFTLot,
    totalANFTLot,
    totalMoNFTLot,
  ]);

  const getInputValue = async () => {
    if (walletConnected) {
      if (username === "") {
        setsubmitname(true);
      }
      if (useremail === "") {
        setsubmitemail(true);
      }
      if (username !== "" && useremail !== "") {
        const payload = {
          name: username,
          email: useremail,
          twitter: twiterhandle,
          isFollow: true,
          retweet: true,
          join: true,
          feedback: remarks,
          tier: tier,
        };
        const result = await GameRegisterInfo.addRegisterInfo(+id, payload);
        if (!result.error) {
          setShowMsg(true);
        } else {
          toast(result.error, { type: toast.TYPE.ERROR });
        }
      } else {
        console.log("Failed");
      }
    } else {
      setloginShow(true);
    }
  };

  return (
    <>
      <div className="container">
        <div className="apply-wrap">
          <div className="apply-header">
            <div className="game-apply-info">
              <div className="game-apply-img">
                <img src={gameDetail?.logo} alt="" />
              </div>
              <div className="game-apply-content">
                <h4>{gameDetail?.name}</h4>
                <span className="game-dark-info">
                  {gameDetail?.poolInfo?.tokenSymbol} /
                  {TokenType[gameDetail?.poolInfo?.tokenVarious || 0]}
                </span>
              </div>
            </div>
            <div className="game-apply-iteminfo">
              <div className="game-apply-list">
                <span className="apply-list-label">Total Raise</span>
                <span className="apply-list-labelinfo">
                  {totalRaise}
                  {TokenType[gameDetail?.poolInfo?.tokenVarious || 0]}
                </span>
              </div>
              <div className="game-apply-list">
                <span className="apply-list-label">Personal Allocation</span>
                <span className="apply-list-labelinfo">
                  {gameDetail?.poolInfo?.min1}-
                  {gameDetail?.poolInfo && gameDetail?.poolInfo?.max5 > 0
                    ? gameDetail?.poolInfo?.max5
                    : "Unlimited"}
                </span>
              </div>
              <div className="game-apply-list">
                <span className="apply-list-label">IDO Date</span>
                <span className="apply-list-labelinfo">
                  {gameDetail?.poolInfo
                    ? new Date(gameDetail?.poolInfo?.startTime).toDateString()
                    : "XXXX"}
                </span>
              </div>
            </div>
          </div>

          <div className="join-safelist-box">
            <div className="join-safelist-header">
              <h4>Join The Safelist</h4>
              <p>{gameDetail && gameDetail.description}</p>
            </div>

            <div className="join-safelist-step">
              <h3>Basic Information</h3>
              <div className="submit-form-group">
                <label className="submit-label">Full Name</label>
                <input
                  type="text"
                  className="submit-form-control"
                  onChange={(event) => setusername(event.target.value)}
                  onClick={(event) => setsubmitname(false)}
                />
                {submitname === true && (
                  <span className="alert">Please input your name</span>
                )}
              </div>
              <div className="submit-form-group">
                <label className="submit-label">Email</label>
                <input
                  type="text"
                  className="submit-form-control"
                  onChange={(event) => setuseremail(event.target.value)}
                  onClick={() => setsubmitemail(false)}
                />
                {submitemail === true && (
                  <span className="alert">Please input your email</span>
                )}
              </div>
              <div className="submit-form-group">
                <label className="submit-label">
                  ERC20 Wallet Address
                </label>
                <input
                  type="text"
                  className="submit-form-control"
                  value={userAddress}
                  disabled
                />
              </div>
              <div className="submit-form-group">
                <label className="submit-label">Your Twitter Handle</label>
                <input
                  type="text"
                  className="submit-form-control"
                  onChange={(event) => settwiterhandle(event.target.value)}
                />
              </div>
            </div>

            <div className="join-safelist-step">
              <h3>Available tier</h3>
              <div className="submit-form-group">
                <label className="submit-label">
                  Choose your tier from the tier list
                </label>
                <select
                  className="submit-form-control"
                  onChange={(e) => setTier(+e.target.value)}
                >
                  {tierlist && tierlist.length > 0 ? (
                    tierlist.map((item: any, index: number) => (
                      <Fragment key={item.tokenId + '_' + index}>
                        {item.tokenId === TierType.settler && (
                          <option key={'settler_' + index} value={TierType.settler}>
                            Normies * {item.tokenQuantity}
                          </option>
                        )}
                        {item.tokenId === TierType.merchant && (
                          <option key={'merchant_' + index} value={TierType.merchant}>
                            Pure Degens * {item.tokenQuantity}
                          </option>
                        )}
                        {item.tokenId === TierType.knight && (
                          <option key={'knight_' + index} value={TierType.knight}>
                            Based Chads * {item.tokenQuantity}
                          </option>
                        )}
                        {item.tokenId === TierType.archon && (
                          <option key={'archon_' + index} value={TierType.archon}>
                            Diamond Ape * {item.tokenQuantity}
                          </option>
                        )}
                        {item.tokenId === TierType.monarch && (
                          <option key={'monarch_' + index} value={TierType.monarch}>
                            Memillionaire * {item.tokenQuantity}
                          </option>
                        )}
                      </Fragment>
                    ))
                  ) : (
                    <option value="-1">No Tier Available</option>
                  )}
                </select>
                {submitname === true && (
                  <span className="alert">Please submit any Tier</span>
                )}
                {tierlist.length === 0 && (
                  <span className="d-block alert-white">
                    Please select a tier or purchase one HERE
                  </span>
                )}
              </div>
            </div>

            <div className="join-safelist-step">
              <h3>Social Media</h3>
              <div className="apply-media-list">
                <div className="apply-media-display">
                  <div className="apply-media-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M30.7076 9.70807L26.9701 13.4456C26.2151 22.1981 18.8338 29.0006 10.0001 29.0006C8.18506 29.0006 6.68881 28.7131 5.55256 28.1456C4.63631 27.6868 4.26131 27.1956 4.16756 27.0556C4.08397 26.9302 4.02978 26.7876 4.00906 26.6383C3.98834 26.4891 4.00161 26.3371 4.04789 26.1937C4.09417 26.0503 4.17225 25.9192 4.27631 25.8102C4.38036 25.7012 4.5077 25.6172 4.64881 25.5643C4.68131 25.5518 7.67881 24.4006 9.58256 22.2093C8.52679 21.3413 7.60516 20.3219 6.84756 19.1843C5.29756 16.8831 3.56256 12.8856 4.09756 6.91182C4.11452 6.722 4.18536 6.54098 4.30175 6.39007C4.41814 6.23917 4.57523 6.12466 4.75451 6.06004C4.93379 5.99542 5.12781 5.98338 5.31371 6.02532C5.49961 6.06727 5.66965 6.16147 5.80381 6.29682C5.84756 6.34057 9.96381 10.4343 14.9963 11.7618V11.0006C14.9944 10.2023 15.1522 9.41174 15.4605 8.6754C15.7688 7.93907 16.2213 7.27186 16.7913 6.71307C17.3449 6.16022 18.0037 5.72387 18.7287 5.42975C19.4537 5.13562 20.2302 4.98969 21.0126 5.00057C22.062 5.01092 23.0909 5.29263 23.9993 5.81832C24.9076 6.34401 25.6645 7.09579 26.1963 8.00057H30.0001C30.198 8.00041 30.3915 8.05897 30.556 8.16885C30.7206 8.27872 30.8489 8.43495 30.9247 8.61778C31.0004 8.8006 31.0202 9.00179 30.9816 9.19587C30.9429 9.38996 30.8476 9.56821 30.7076 9.70807Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="apply-social-info">
                    <h5>Follow</h5>
                    <span>{gameDetail?.name} Twitter</span>
                  </div>
                </div>
                <div className="apply-media-action">
                  <div className="feed-action">
                    <button
                      type="button"
                      className="feed-action-link btn-action-link"
                      onClick={updategotofeed}
                    >
                      Go to Feed
                    </button>
                  </div>
                  <div className="did-action">
                    {gotoFeed === false && (
                      <button
                        type="button"
                        className={`btn-action-link`}
                        onClick={() => setgotoFeed(true)}
                      >
                        I DID THIS
                      </button>
                    )}
                    {gotoFeed === true && (
                      <img src={CheckGreenIcon.default} alt="" />
                    )}
                  </div>

                  {/* <div className="did-action">
                    <img src={checkIcon.default} alt="" />
                  </div> */}
                </div>
              </div>
              <div className="apply-media-list">
                <div className="apply-media-display">
                  <div className="apply-media-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M30.7076 9.70807L26.9701 13.4456C26.2151 22.1981 18.8338 29.0006 10.0001 29.0006C8.18506 29.0006 6.68881 28.7131 5.55256 28.1456C4.63631 27.6868 4.26131 27.1956 4.16756 27.0556C4.08397 26.9302 4.02978 26.7876 4.00906 26.6383C3.98834 26.4891 4.00161 26.3371 4.04789 26.1937C4.09417 26.0503 4.17225 25.9192 4.27631 25.8102C4.38036 25.7012 4.5077 25.6172 4.64881 25.5643C4.68131 25.5518 7.67881 24.4006 9.58256 22.2093C8.52679 21.3413 7.60516 20.3219 6.84756 19.1843C5.29756 16.8831 3.56256 12.8856 4.09756 6.91182C4.11452 6.722 4.18536 6.54098 4.30175 6.39007C4.41814 6.23917 4.57523 6.12466 4.75451 6.06004C4.93379 5.99542 5.12781 5.98338 5.31371 6.02532C5.49961 6.06727 5.66965 6.16147 5.80381 6.29682C5.84756 6.34057 9.96381 10.4343 14.9963 11.7618V11.0006C14.9944 10.2023 15.1522 9.41174 15.4605 8.6754C15.7688 7.93907 16.2213 7.27186 16.7913 6.71307C17.3449 6.16022 18.0037 5.72387 18.7287 5.42975C19.4537 5.13562 20.2302 4.98969 21.0126 5.00057C22.062 5.01092 23.0909 5.29263 23.9993 5.81832C24.9076 6.34401 25.6645 7.09579 26.1963 8.00057H30.0001C30.198 8.00041 30.3915 8.05897 30.556 8.16885C30.7206 8.27872 30.8489 8.43495 30.9247 8.61778C31.0004 8.8006 31.0202 9.00179 30.9816 9.19587C30.9429 9.38996 30.8476 9.56821 30.7076 9.70807Z"
                        fill="black"
                      />
                    </svg>
                  </div>

                  <div className="apply-social-info">
                    <h5>Retweet</h5>
                    <span>{gameDetail?.name} Twitter</span>
                  </div>
                </div>
                <div className="apply-media-action">
                  <div className="feed-action">
                    <button
                      type="button"
                      className="feed-action-link btn-action-link"
                      onClick={updateretweet}
                    >
                      Go to Tweet
                    </button>
                  </div>
                  <div className="did-action">
                    {retweet === false && (
                      <button
                        type="button"
                        className={`btn-action-link`}
                        onClick={() => setretweet(true)}
                      >
                        I DID THIS
                      </button>
                    )}
                    {retweet === true && (
                      <img src={CheckGreenIcon.default} alt="" />
                    )}
                  </div>
                </div>
              </div>
              <div className="apply-media-list">
                <div className="apply-media-display">
                  <div className="apply-media-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M30.7076 9.70807L26.9701 13.4456C26.2151 22.1981 18.8338 29.0006 10.0001 29.0006C8.18506 29.0006 6.68881 28.7131 5.55256 28.1456C4.63631 27.6868 4.26131 27.1956 4.16756 27.0556C4.08397 26.9302 4.02978 26.7876 4.00906 26.6383C3.98834 26.4891 4.00161 26.3371 4.04789 26.1937C4.09417 26.0503 4.17225 25.9192 4.27631 25.8102C4.38036 25.7012 4.5077 25.6172 4.64881 25.5643C4.68131 25.5518 7.67881 24.4006 9.58256 22.2093C8.52679 21.3413 7.60516 20.3219 6.84756 19.1843C5.29756 16.8831 3.56256 12.8856 4.09756 6.91182C4.11452 6.722 4.18536 6.54098 4.30175 6.39007C4.41814 6.23917 4.57523 6.12466 4.75451 6.06004C4.93379 5.99542 5.12781 5.98338 5.31371 6.02532C5.49961 6.06727 5.66965 6.16147 5.80381 6.29682C5.84756 6.34057 9.96381 10.4343 14.9963 11.7618V11.0006C14.9944 10.2023 15.1522 9.41174 15.4605 8.6754C15.7688 7.93907 16.2213 7.27186 16.7913 6.71307C17.3449 6.16022 18.0037 5.72387 18.7287 5.42975C19.4537 5.13562 20.2302 4.98969 21.0126 5.00057C22.062 5.01092 23.0909 5.29263 23.9993 5.81832C24.9076 6.34401 25.6645 7.09579 26.1963 8.00057H30.0001C30.198 8.00041 30.3915 8.05897 30.556 8.16885C30.7206 8.27872 30.8489 8.43495 30.9247 8.61778C31.0004 8.8006 31.0202 9.00179 30.9816 9.19587C30.9429 9.38996 30.8476 9.56821 30.7076 9.70807Z"
                        fill="black"
                      />
                    </svg>
                  </div>
                  <div className="apply-social-info">
                    <h5>Join</h5>
                    <span>{gameDetail?.name} Twitter</span>
                  </div>
                </div>
                <div className="apply-media-action">
                  <div className="feed-action">
                    <button
                      type="button"
                      className="feed-action-link btn-action-link"
                      onClick={updatejoin}
                    >
                      Join Channel
                    </button>
                  </div>
                  <div className="did-action">
                    {join === false && (
                      <button
                        type="button"
                        className={`btn-action-link`}
                        onClick={() => setjoin(true)}
                      >
                        I DID THIS
                      </button>
                    )}
                    {join === true && (
                      <img src={CheckGreenIcon.default} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="join-safelist-step">
              <h3>About {gameDetail?.name}</h3>
              <div className="submit-form-group">
                <label className="submit-label">
                  How awesome is {gameDetail && gameDetail.name}?
                </label>
                <input
                  type="text"
                  className="submit-form-control"
                  onChange={(event) => setremarks(event.target.value)}
                />
              </div>
              <div className="submit-form-group">
                <label className="submit-label">
                  Do you agree with the{" "}
                  <a href="/terms">Terms and Conditions</a>
                </label>
                <div className="apply-checkbox-listnew">
                  <label className="checkbox-label">
                    <span>I accept the Terms of Service</span>
                    <input
                      type="checkbox"
                      id="terms"
                      checked={termscondition}
                      onChange={getValuefortermsconditions}
                    />
                    <span className="mark"></span>
                  </label>
                  {receivealert !== true && (
                    <span className="alert">
                      (Please select terms of service)
                    </span>
                  )}
                </div>
              </div>
              <div className="submit-form-group">
                <label className="submit-label">
                  Do you want to receive updates from{" "}
                  {gameDetail && gameDetail.name}
                </label>
                <div className="apply-checkbox-listnew">
                  <label className="checkbox-label">
                    <span>
                      Yes, I agree to receive updates from{" "}
                      {gameDetail && gameDetail.name} in the future
                    </span>
                    <input
                      type="checkbox"
                      id="agree"
                      alt=""
                      onChange={getValueforupdate}
                    />
                    <span className="mark1"></span>
                  </label>
                </div>
              </div>

              <div className="submit-apply-action">
                <button
                  type="button"
                  className={`btn-action-link ${
                    !termscondition && "noncursor-pointer"
                  }`}
                  onClick={getInputValue}
                  disabled={!termscondition}
                >
                  Submit Your Application
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showMsg}
        closeModal={() => {
          closeModal();
          history.push(`${Paths.account}/participating-game`);
        }}
        className="pb-5"
      >
        <div className="register-innerbg">
          <h3>Thank You</h3>
          <div className="successfully">
            <h4>Your registration request has been submitted successfully.</h4>
            <div className="d-flex justify-content-center align-items-center">
              <button
                type="button"
                className={`btn-action-link mt-4 p-3`}
                onClick={() => {
                  closeModal();
                  history.push(`${Paths.account}/participating-game`);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal show={loginshow} closeModal={closeModal}>
        <LoginModalContainer show={loginshow} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default PoolRegistration;
