import { utils, providers } from 'ethers'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'

export const signMessageAsync = async (signer: any, address: any, message: any) => {
  const messageBytes = utils.toUtf8Bytes(message)
  if (signer instanceof providers.JsonRpcSigner) {
    try {
      const signature = await signer.provider.send('personal_sign', [
        utils.hexlify(messageBytes),
        toLower(address),
      ])
      return signature
    } catch (e: any) {
      if (includes(e?.message, 'personal_sign')) {
        return await signer.signMessage(messageBytes)
      }
    }
  } else {
    return await signer.signMessage(messageBytes)
  }
}

