import React, { useState } from "react";
import { useLifecycles } from "react-use";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Slider from "react-slick";

import "./styles.scss";

import { SuccessBox, CongratsText, SuccessText, CloseSuccess } from "./style";
import { CrossIcon } from "../../images";
import {
  StakingLotModals,
  SuccessMsgType,
  RoyaStaking,
  Paths,
  Storage,
} from "../../utils";
import {
  BuySettler,
  BuyMerchant,
  BuyKnight,
  BuyArchon,
  BuyMonarch,
  Modal,
  SellSettlerRoya,
  SellMerchantRoya,
  SellMonarchRoya,
  SellArchonRoya,
  SellKnightRoya,
} from "../../components";

import {
  getUserTotalLots,
  closeSuccessMsg,
  getStakeDateforAllTiers,
} from "../../logic/actions";
import { RoyaStakeItem } from "../../components/roya-modals/roya-stake-item";
import { renderLotsItem } from "./components/lots";
import {
  renderAllocationItem,
} from "./components/allocation";

const settings = {
  className: "center",
  centerMode: true,
  infinite: false,
  centerPadding: "10px",
  slidesToShow: 1,
  speed: 500,
};

const StakingLots = () => {
  const [modalStatus, setModalStatus] = useState(StakingLotModals.closed);

  const {
    totalKroyaLot,
    totalAroyaLot,
    totalSroyaLot,
    totalMroyaLot,
    totalMoroyaLot,
    totalSNFTLot,
    totalMNFTLot,
    totalKNFTLot,
    totalANFTLot,
    totalMoNFTLot,
    lotTypeBought,
    lotAmountBought,
    lotTypeSold,
    lotAmountSold,
    successMsgType,
    settlerLotbuy,
    merchantLotbuy,
    knightLotbuy,
    archonLotbuy,
    monarchLotbuy,
    settlerNFTLotbuy,
    merchantNFTLotbuy,
    knightNFTLotbuy,
    archonNFTLotbuy,
    monarchNFTLotbuy,
  } = useSelector((state: any) => state.stakingLots);

  const { userAddress, walletConnected } = useSelector(
    (state: any) => state.user
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [showMsg, setshowMsg] = useState(false);

  const { userRoyaBalance } = useSelector((state: any) => state.royaReserve);

  useLifecycles(() => {
    if (!Storage.accessToken) {
      toast("Please connect your wallet", {
        type: toast.TYPE.WARNING,
      });
    }
  });

  React.useEffect(() => {
    if (walletConnected) {
      /// need to replace
      dispatch(getStakeDateforAllTiers(userAddress));
      dispatch(getUserTotalLots(userAddress));
    }
  }, [userAddress, dispatch, walletConnected, userRoyaBalance]);

  const openModal = (modalType: number) => {
    setModalStatus(modalType);
  };

  const closeModal = () => {
    setModalStatus(StakingLotModals.closed);
  };

  const closeLockModal = () => {
    setshowMsg(false);
  };

  const handleCloseMsg = () => {
    dispatch(closeSuccessMsg());
  };

  const openPancakeSwap = () => {
    window.open(
      "https://pancakeswap.finance/swap?inputCurrency=0x99415856b37be9e75c0153615c7954f9ddb97a6e&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
      "_blank"
    );
  };

  return (
    <>
      <div className="page-container staking-lots">
        <SuccessBox className="custom-success-box" show={successMsgType === SuccessMsgType.showBuySuccess}>
          <CongratsText className="success-text-color">Congratulations!</CongratsText>
          <SuccessText className="success-info-color">
            {`You have acquired ${lotAmountBought} ${lotTypeBought} ${
              lotAmountBought > 1 ? "lots" : "lot"
            }!`}
          </SuccessText>
          <CloseSuccess onClick={handleCloseMsg}>
            <img src={CrossIcon.default} alt="close" />
          </CloseSuccess>
        </SuccessBox>
        <SuccessBox show={successMsgType === SuccessMsgType.showSellSuccess}>
          <CongratsText>Congratulations!</CongratsText>
          <SuccessText>
            {`You have unstaked ${lotAmountSold} ${lotTypeSold} ${
              lotAmountSold > 1 ? "lots" : "lot"
            }!`}
          </SuccessText>
          <CloseSuccess onClick={handleCloseMsg}>
            <img src={CrossIcon.default} alt="close" />
          </CloseSuccess>
        </SuccessBox>
        <div className="staking-lots-wrapper">
          <div className="section-header">
            <div className="section-header-wrapper">
              <span>Staking Lots</span>
            </div>
          </div>
          <div className="staking-listrow">
            <RoyaStakeItem
              model={RoyaStaking.settler}
              totalRoyaLot={totalSroyaLot}
              totalNFTLot={totalSNFTLot}
              onClickStake={openModal}
              onClickUnstake={openModal}
            />
            <RoyaStakeItem
              model={RoyaStaking.merchant}
              totalRoyaLot={totalMroyaLot}
              totalNFTLot={totalMNFTLot}
              onClickStake={openModal}
              onClickUnstake={openModal}
            />
            <RoyaStakeItem
              model={RoyaStaking.knight}
              totalRoyaLot={totalKroyaLot}
              totalNFTLot={totalKNFTLot}
              onClickStake={openModal}
              onClickUnstake={openModal}
            />
            <RoyaStakeItem
              model={RoyaStaking.archon}
              totalRoyaLot={totalAroyaLot}
              totalNFTLot={totalANFTLot}
              onClickStake={openModal}
              onClickUnstake={openModal}
            />
            <RoyaStakeItem
              model={RoyaStaking.monarch}
              totalRoyaLot={totalMoroyaLot}
              totalNFTLot={totalMoNFTLot}
              onClickStake={openModal}
              onClickUnstake={openModal}
            />
          </div>
          <div className="staking-listrow-slider-mobile d-none">
            <Slider {...settings}>
              <RoyaStakeItem
                model={RoyaStaking.settler}
                totalRoyaLot={totalSroyaLot}
                totalNFTLot={totalSNFTLot}
                onClickStake={openModal}
                onClickUnstake={openModal}
              />
              <RoyaStakeItem
                model={RoyaStaking.merchant}
                totalRoyaLot={totalMroyaLot}
                totalNFTLot={totalMNFTLot}
                onClickStake={openModal}
                onClickUnstake={openModal}
              />
              <RoyaStakeItem
                model={RoyaStaking.knight}
                totalRoyaLot={totalKroyaLot}
                totalNFTLot={totalKNFTLot}
                onClickStake={openModal}
                onClickUnstake={openModal}
              />
              <RoyaStakeItem
                model={RoyaStaking.archon}
                totalRoyaLot={totalAroyaLot}
                totalNFTLot={totalANFTLot}
                onClickStake={openModal}
                onClickUnstake={openModal}
              />
              <RoyaStakeItem
                model={RoyaStaking.monarch}
                totalRoyaLot={totalMoroyaLot}
                totalNFTLot={totalMoNFTLot}
                onClickStake={openModal}
                onClickUnstake={openModal}
              />
            </Slider>
          </div>

          <div className="staking-applications-box tier-comparison desktop">
            <div className="tier-com-applications-list-item tier-header-line">
              <div className="tier-applications-col title">Tier</div>
              <div className="tier-applications-col">Staked</div>
            </div>
            {renderLotsItem(RoyaStaking.settler, totalSroyaLot)}
            {renderLotsItem(RoyaStaking.merchant, totalMroyaLot)}
            {renderLotsItem(RoyaStaking.knight, totalKroyaLot)}
            {renderLotsItem(RoyaStaking.archon, totalAroyaLot)}
            {renderLotsItem(RoyaStaking.monarch, totalMoroyaLot)}
          </div>

          <div className="section-header tier-comparison tier-comparison-title">
            <div className="section-header-wrapper">
              <span>TIER COMPARISON</span>
            </div>
          </div>
          <div className="staking-applications-box tier-comparison allocation-container desktop">
            <div className="tier-com-applications-list-item tier-header-line">
              <div className="tier-applications-col title">TIER</div>
              <div className="tier-applications-col">SLOTS</div>
              <div className="tier-applications-col">STAKED $WEE</div>
              <div className="tier-applications-col">ALLOCATION</div>
            </div>
            {renderAllocationItem(RoyaStaking.settler)}
            {renderAllocationItem(RoyaStaking.merchant)}
            {renderAllocationItem(RoyaStaking.knight)}
            {renderAllocationItem(RoyaStaking.archon)}
            {renderAllocationItem(RoyaStaking.monarch)}
          </div>

          <div className="allocation-pool-row">
            <h2>
              This example is based on 300 BNB allocation.
              <br />
              <br />
              Allocation per ticket will vary depending on the total allocation
              of the pool.
            </h2>
          </div>

          <div className="staking-action-row">
            <button
              type="button"
              className="btn-action-link"
              onClick={openPancakeSwap}
            >
              Ticker TBD
            </button>
            <button
              type="button"
              className="btn-action-link"
              onClick={() => history.push(Paths.nfts)}
            >
              Get Staking Lots
            </button>
          </div>
        </div>
        <Modal
          show={modalStatus === StakingLotModals.buysettler}
          closeModal={closeModal}
        >
          <BuySettler closeModal={closeModal} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.buymerchant}
          closeModal={closeModal}
        >
          <BuyMerchant closeModal={closeModal} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.buyKnight}
          closeModal={closeModal}
        >
          <BuyKnight closeModal={closeModal} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.buyArchon}
          closeModal={closeModal}
        >
          <BuyArchon closeModal={closeModal} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.buyMonarch}
          closeModal={closeModal}
        >
          <BuyMonarch closeModal={closeModal} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellSettler}
          closeModal={closeModal}
        >
          <SellSettlerRoya
            showLockModal={setshowMsg}
            lotBought={settlerLotbuy}
            nftLotBought={settlerNFTLotbuy}
            closeModal={closeModal}
          />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellMerchant}
          closeModal={closeModal}
        >
          <SellMerchantRoya
            showLockModal={setshowMsg}
            lotBought={merchantLotbuy}
            nftLotBought={merchantNFTLotbuy}
            closeModal={closeModal}
          />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellKnight}
          closeModal={closeModal}
        >
          <SellKnightRoya
            showLockModal={setshowMsg}
            lotBought={knightLotbuy}
            nftLotBought={knightNFTLotbuy}
            closeModal={closeModal}
          />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellArchon}
          closeModal={closeModal}
        >
          <SellArchonRoya
            showLockModal={setshowMsg}
            lotBought={archonLotbuy}
            nftLotBought={archonNFTLotbuy}
            closeModal={closeModal}
          />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellMonarch}
          closeModal={closeModal}
        >
          <SellMonarchRoya
            showLockModal={setshowMsg}
            lotBought={monarchLotbuy}
            nftLotBought={monarchNFTLotbuy}
            closeModal={closeModal}
          />
        </Modal>

        <Modal show={showMsg} closeModal={closeLockModal}>
          <div className="register-innerbg">
            <div className="successfully">
              <h4>
                Your staking is locked for 8 days. After that time you are free
                to withdraw at any time.
              </h4>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default StakingLots;
