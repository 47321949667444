import React from "react";

import "./styles.scss";

import clsxm from "src/lib/clsxm";

interface Props {
  className?: string;
  onSelect?: (files: any) => void;
  children: React.ReactNode;
  fileName: string;
}

export const SelectFileForm: React.FC<Props> = ({
  className = "",
  onSelect = () => true,
  children,
  fileName,
}) => {
  const inputRef = React.useRef(null);

  const [dragActive, setDragActive] = React.useState(false);

  // handle drag events
  function handleDrag(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  }

  // triggers when file is dropped
  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleFile(event.dataTransfer.files);
    }
  }

  // triggers when file is selected with click
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      handleFile(event.target.files);
    }
  }

  async function handleFile(files: any) {
    onSelect(files);
  }

  return (
    <div
      id="form-file-upload"
      className={clsxm(
        "select-file-form",
        dragActive ? "drag-active" : "",
        className
      )}
      onDragEnter={(event) => handleDrag(event)}
    >
      <label className="upload-form-wrapper" htmlFor="input-file-upload">
        <input
          ref={inputRef}
          id="input-file-upload"
          type="file"
          multiple
          onChange={(event) => handleChange(event)}
        />
        {children}
      </label>
      {fileName && (
        <span style={{ color: "gray", marginLeft: "8px" }}>{fileName}</span>
      )}
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={(event) => handleDrag(event)}
          onDragLeave={(event) => handleDrag(event)}
          onDragOver={(event) => handleDrag(event)}
          onDrop={(event) => handleDrop(event)}
        />
      )}
    </div>
  );
};
