import './style.scss';

export const PageNotFound = () => {
  return (
    <>
      <div className="container">
        <div className="page-not-found-container flex flex-column justify-center items-center">
          <p className="label-404">404</p>
          <p>Page not found :c</p>
          <a href="/" className="btn-action-link">
            Return Home
          </a>
        </div>
      </div>
    </>
  );
};
