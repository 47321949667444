import styled from "styled-components";
import { SecondaryGradientButton } from "../../../../utils";

export const Header = styled.div`
  text-align: left;
  line-height: 19px;
  color: var(--Black-Black-100, #000);
  font-size: 30px;
  line-height: 120%; /* 36px */
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 31px;
`;

export const ConnectCont = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 85px;
`;

export const ConnectBtn = styled(SecondaryGradientButton)`
  width: 164px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;
