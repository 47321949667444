/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

import { CrossIcon } from "../../images";

import {
  StakingLotModals,
  ModalTypes,
  getNetworkurlArgs,
  getsettlerLotBalance,
  getmerchantLotBalance,
  Paths,
  SuccessMsgType,
  apiUrl,
  thousandSeparator,
  getKnightLotContract,
  getArchonContract,
  getMonarchLotContract,
} from "../../utils";
import {
  BuySettler,
  BuyMerchant,
  BuyKnight,
  BuyArchon,
  BuyMonarch,
  Modal,
  SellSettlerRoya,
  SellMerchantRoya,
  SellMonarchRoya,
  SellArchonRoya,
  SellKnightRoya,
} from "../../components";
import {
  closeSuccessMsg,
  getmerchantLotContractBalance,
  getSettlerLotContractBalance,
  getStakeDateforAllTiers,
  getuserregistrationpooldetails,
  getUserTotalLots,
} from "../../logic/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import {
  SuccessBox,
  CongratsText,
  SuccessText,
  CloseSuccess,
} from "../staking-lots/style";

import { TireStakeBox } from "./tire-stake-box";

const Dashboard = () => {
  const {
    totalKroyaLot,
    totalAroyaLot,
    totalSroyaLot,
    totalMroyaLot,
    totalMoroyaLot,
    lotTypeBought,
    lotAmountBought,
    lotTypeSold,
    lotAmountSold,
    successMsgType,
    settlerLotbuy,
    merchantLotbuy,
    knightLotbuy,
    archonLotbuy,
    monarchLotbuy,
    settlerNFTLotbuy,
    merchantNFTLotbuy,
    knightNFTLotbuy,
    archonNFTLotbuy,
    monarchNFTLotbuy,
  } = useSelector((state: any) => state.stakingLots);

  const [modalStatus, setModalStatus] = useState(ModalTypes.closed);
  const openModalforlot = (modalType: number) => {
    setModalStatus(modalType);
  };
  const closeModalforlot = () => {
    setModalStatus(StakingLotModals.closed);
  };
  const closeModal = () => {
    setshowMsg(false);
  };

  const { userAddress, walletConnected, network } = useSelector(
    (state: any) => state.user
  );

  const Singledata = {
    Status: "",
    Selectedpool: "",
    Address: "",
    StatusDate: "",
  };
  const singleaddress: any[] | (() => any[]) = [];

  const dispatch = useDispatch();

  const settlerlotamount = 1000;
  const merchantlotamount = 5000;
  const knightlotamount = 50000;
  const archonlotamount = 10000;
  const royaleflashlotamount = 250000;
  const handleCloseMsg = () => {
    dispatch(closeSuccessMsg());
  };
  const [, setSettlerLot] = useState(0);
  const [LotStatus, setLotStatus] = useState(false);
  const [, setMerchantLot] = useState(0);
  const [, setKingLot] = useState(0);
  const [, setQueenLot] = useState(0);
  const [, setRoyaleflashLot] = useState(0);
  const [stakeSelectionValue, setstakeSelectionValue] = useState("");
  const [unstakeSelectionValue, setunstakeSelectionValue] = useState("");
  const [classValue, setClassValue] = useState(true);
  const [stakevalue, setstakevalue] = useState("100021.123");
  const { userRoyaBalance } = useSelector((state: any) => state.royaReserve);
  const { pools } = useSelector((state: any) => state.pools);
  const [, setActiveAllocationData] = useState(singleaddress);
  const [totalRoyaValue, settotalRoyaValue] = useState(0);
  const [, setSettlerLotAmount] = useState(0);
  const [, setMerchantLotAmount] = useState(0);
  const [, setKingLotAmount] = useState(0);
  const [, setQueenLotAmount] = useState(0);
  const [, setRoyaleflashLotAmount] = useState(0);
  const [showMsg, setshowMsg] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getstakevalue();
    getstakevalueNEW();
    if (walletConnected) {
      dispatch(getmerchantLotContractBalance(userAddress));
      dispatch(getSettlerLotContractBalance(userAddress));
      dispatch(getStakeDateforAllTiers(userAddress));
      dispatch(getUserTotalLots(userAddress));
      getPoolRegistrationStatus();
    }
  }, [walletConnected, pools, userRoyaBalance]);

  const getstakevalueNEW = () => {
    axios
      .get(getNetworkurlArgs(network))
      .then((res) => {
        let data = res.data;
        setstakevalue(data);
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          alert("Address is already registered for this pool");
        }
      });
  };

  // const GoToPage = (arr: any) => {
  //   history.push(arr);
  // };

  const getPoolRegistrationStatus = async () => {
    let settlerLot = await getsettlerLotBalance()
      .methods.balanceOf(userAddress)
      .call();
    let merchantLot = await getmerchantLotBalance()
      .methods.balanceOf(userAddress)
      .call();
    let kingLot = await getKnightLotContract()
      .methods.balanceOf(userAddress)
      .call();
    let queenLot = await getArchonContract()
      .methods.balanceOf(userAddress)
      .call();
    let royalflashLot = await getMonarchLotContract()
      .methods.balanceOf(userAddress)
      .call();

    let totalstackedroyabalance =
      settlerLot * settlerlotamount +
      merchantLot * merchantlotamount +
      kingLot * knightlotamount +
      queenLot * archonlotamount +
      royalflashLot * royaleflashlotamount;
    settotalRoyaValue(totalstackedroyabalance);
    setSettlerLot(settlerLot);
    setMerchantLot(merchantLot);
    setKingLot(kingLot);
    setQueenLot(queenLot);
    setRoyaleflashLot(royalflashLot);

    const usersettlerlotamount = settlerLot * settlerlotamount;
    const usermerchantlotamount = merchantLot * merchantlotamount;
    const userknightlotamount = kingLot * knightlotamount;
    const userarchonlotamount = queenLot * archonlotamount;
    const userroyaleflashlotamount = royalflashLot * royaleflashlotamount;

    setSettlerLotAmount(usersettlerlotamount);
    setMerchantLotAmount(usermerchantlotamount);
    setKingLotAmount(userknightlotamount);
    setQueenLotAmount(userarchonlotamount);
    setRoyaleflashLotAmount(userroyaleflashlotamount);

    if (totalstackedroyabalance > 0) {
      setLotStatus(true);
    }

    const data = {
      Address: userAddress,
    };
    const registerdata: any[] = [];
    dispatch(getuserregistrationpooldetails(registerdata));
    await axios
      .post(`${apiUrl}/getuserpooldetails`, data)
      .then((res) => {
        let newdata = res.data;
        const registeraddress: React.SetStateAction<any[]> = [];
        newdata.forEach((i: typeof Singledata) => {
          registeraddress.push(i.Selectedpool);
        });
        setActiveAllocationData(registeraddress);
        console.log(registeraddress);

        // pools.forEach((i: any) => {
        //   if (registeraddress.includes(i.PoolAddress) == true) {
        //     registerdata.push(i)
        //   }
        // })

        dispatch(getuserregistrationpooldetails(newdata));
        // setuserpoolregsitrationdata(registerdata)

        newdata.forEach((i: typeof Singledata) => {
          if (i.Status === "Applied") {
            if (userroyaleflashlotamount > userarchonlotamount) {
              const payload = {
                Selectedpool: i.Selectedpool,
                Address: userAddress,
                Status: "Pre-Selected KYC Open",
                Tier: 5,
              };
              axios.post(`${apiUrl}/updatepoolstatus`, payload).then((res) => {
                console.log(res.data);
                getPoolRegistrationStatus();
              });
            } else if (userarchonlotamount > userknightlotamount) {
              const payload = {
                Selectedpool: i.Selectedpool,
                Address: userAddress,
                Status: "Pre-Selected KYC Open",
                Tier: 4,
              };
              axios.post(`${apiUrl}/updatepoolstatus`, payload).then((res) => {
                console.log(res.data);
                getPoolRegistrationStatus();
              });
            } else if (userknightlotamount > usermerchantlotamount) {
              const payload = {
                Selectedpool: i.Selectedpool,
                Address: userAddress,
                Status: "Pre-Selected KYC Open",
                Tier: 3,
              };
              axios.post(`${apiUrl}/updatepoolstatus`, payload).then((res) => {
                console.log(res.data);
                getPoolRegistrationStatus();
              });
            } else if (usermerchantlotamount > usersettlerlotamount) {
              const payload = {
                Selectedpool: i.Selectedpool,
                Address: userAddress,
                Status: "Pre-Selected KYC Open",
                Tier: 2,
              };
              axios.post(`${apiUrl}/updatepoolstatus`, payload).then((res) => {
                console.log(res.data);
                getPoolRegistrationStatus();
              });
            } else if (usersettlerlotamount >= 1000) {
              const payload = {
                Selectedpool: i.Selectedpool,
                Address: userAddress,
                Status: "Pre-Selected KYC Open",
                Tier: 1,
              };
              axios.post(`${apiUrl}/updatepoolstatus`, payload).then((res) => {
                console.log(res.data);
                getPoolRegistrationStatus();
              });
            }
          }
        });
      })
      .catch((err) => {
        if (err.message === "Request failed with status code 401") {
          alert("Address is already registered for this pool");
        }
      });
  };

  // const GoToGamedetailsPage = (path: any) => {
  //   history.push(`${Paths.gameDetails}/${path}`);
  // };

  const getstakevalue = () => {
    // var data = e.target.value
    // console.log(e.target.value)
    if (stakeSelectionValue === "Settler") {
      openModalforlot(StakingLotModals.buysettler);
    } else if (stakeSelectionValue === "Merchant") {
      console.log(StakingLotModals.buymerchant);
      openModalforlot(StakingLotModals.buymerchant);
    } else if (stakeSelectionValue === "Knight") {
      openModalforlot(StakingLotModals.buyKnight);
    } else if (stakeSelectionValue === "Archon") {
      openModalforlot(StakingLotModals.buyArchon);
    } else if (stakeSelectionValue === "Monarch") {
      openModalforlot(StakingLotModals.buyMonarch);
    }
  };
  const getunstakevalue = () => {
    // var data = e.target.value
    // console.log(e.target.value)
    if (unstakeSelectionValue === "Settler") {
      if (totalSroyaLot !== "0" && settlerLotbuy > 7) {
        openModalforlot(StakingLotModals.sellSettler);
      } else {
        setshowMsg(true);
      }
    } else if (unstakeSelectionValue === "Merchant") {
      if (totalMroyaLot !== "0" && merchantLotbuy > 7) {
        openModalforlot(StakingLotModals.sellMerchant);
      } else {
        setshowMsg(true);
      }
    } else if (unstakeSelectionValue === "Knight") {
      if (totalKroyaLot !== "0" && knightLotbuy > 7) {
        openModalforlot(StakingLotModals.sellKnight);
      } else {
        setshowMsg(true);
      }
    } else if (unstakeSelectionValue === "Archon") {
      if (totalAroyaLot !== "0" && archonLotbuy > 7) {
        openModalforlot(StakingLotModals.sellArchon);
      } else {
        setshowMsg(true);
      }
    } else if (unstakeSelectionValue === "Monarch") {
      if (totalMoroyaLot !== "0" && monarchLotbuy > 7) {
        openModalforlot(StakingLotModals.sellMonarch);
      } else {
        setshowMsg(true);
      }
    }
  };

  const openPancakeSwap = () => {
    window.open(
      "https://pancakeswap.finance/swap?inputCurrency=0x99415856b37be9e75c0153615c7954f9ddb97a6e&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
      "_blank"
    );
  };

  return (
    <>
      <div className="page-container dashboard">
        <SuccessBox className="custom-success-box" show={successMsgType === SuccessMsgType.showBuySuccess}>
          <CongratsText className="success-text-color">Congratulations!</CongratsText>
          <SuccessText className="success-info-color">
            {`You have acquired ${lotAmountBought} ${lotTypeBought} ${
              lotAmountBought > 1 ? "lots" : "lot"
            }!`}
          </SuccessText>
          <CloseSuccess onClick={handleCloseMsg}>
            <img src={CrossIcon.default} alt="close" />
          </CloseSuccess>
        </SuccessBox>
        <SuccessBox show={successMsgType === SuccessMsgType.showSellSuccess}>
          <CongratsText>Congratulations!</CongratsText>
          <SuccessText>
            {`You have unstaked ${lotAmountSold} ${lotTypeSold} ${
              lotAmountSold > 1 ? "lots" : "lot"
            }!`}
          </SuccessText>
          <CloseSuccess onClick={handleCloseMsg}>
            <img src={CrossIcon.default} alt="close" />
          </CloseSuccess>
        </SuccessBox>
        <div className="dashboard-wrapper">
          <div className="dashboard-header">
            <div className="dashboard-header-item">
              <div className="dashboard-roya-update">
                <span className="dashboard-total-text">
                  TOTAL Ticker TBD STAKED BY THS WALLET
                </span>
                <span className="dashboard-roya-count">
                  {thousandSeparator(totalRoyaValue.toString())}
                </span>
              </div>
              <div className="dashboard-roya-update">
                <span className="dashboard-total-text">
                  TOTAL Ticker TBD ON THE BSC NETWORK
                </span>
                <span className="dashboard-roya-count">
                  {thousandSeparator(stakevalue.toString())}
                </span>
              </div>
              <div className="dashboard-roya-action">
                <button type="button" onClick={openPancakeSwap}>
                  Ticker TBD
                </button>
                <Link to={Paths.nfts}>BUY NFT</Link>
              </div>
            </div>

            <div className="dashboard-header-item">
              {userRoyaBalance > 0 && LotStatus === true && (
                <div className="dashboard-tier-stake-box">
                  <div className="staking-nav">
                    <button
                      className={classValue ? "active" : ""}
                      onClick={() => setClassValue(true)}
                    >
                      Stake
                    </button>
                    <button
                      className={classValue ? "" : "active"}
                      onClick={() => setClassValue(false)}
                    >
                      Unstake
                    </button>
                  </div>
                  {classValue === true && (
                    <div>
                      <h4>
                        Stake Ticker TBD or a tier NFT to participate in
                        safelist for upcoming IDOs.
                      </h4>
                      <div className="submit-form-group">
                        <label className="submit-label">Select Tier</label>
                        <div className="dashboard-amount-input">
                          <select
                            defaultValue={""}
                            onChange={(event) =>
                              setstakeSelectionValue(event.target.value)
                            }
                          >
                            <option value="" disabled>
                              Select Tier
                            </option>
                            <option value="Settler">Settler</option>
                            <option value="Merchant">Merchant</option>
                            <option value="Knight">Knight</option>
                            <option value="Archon">Archon</option>
                            <option value="Monarch">Monarch</option>
                          </select>
                        </div>
                      </div>
                      <div className="dashboard-stake-action">
                        <button
                          type="button"
                          className="btn-action-link"
                          onClick={() => getstakevalue()}
                        >
                          Stake
                        </button>
                      </div>
                      <h4>
                        Your $WEE tokens will be locked for 8 days. After that
                        time you can withdraw any time.
                      </h4>
                    </div>
                  )}

                  {classValue === false && (
                    <div>
                      <h4>
                        Stake $WEE/NFT to participate in safelist for upcoming
                        projects.
                      </h4>
                      <div className="submit-form-group">
                        <label className="submit-label">Select Tier</label>
                        <div className="dashboard-amount-input">
                          {/* <input type="text" className="submit-form-control" />
                    <span className="max-amount">MAX</span> */}

                          <select
                            onChange={(event) =>
                              setunstakeSelectionValue(event.target.value)
                            }
                          >
                            <option value="" disabled selected>
                              Select Tier
                            </option>
                            <option value="Settler">Settler</option>
                            <option value="Merchant">Merchant</option>
                            <option value="Knight">Knight</option>
                            <option value="Archon">Archon</option>
                            <option value="Monarch">Monarch</option>
                          </select>
                          {/* <img id='dropdown-image' src={whiteDropdown.default} /> */}
                        </div>
                      </div>
                      <div className="dashboard-stake-action">
                        <button
                          type="button"
                          className="btn-action-link"
                          disabled={unstakeSelectionValue === ""}
                          onClick={() => getunstakevalue()}
                        >
                          Unstake
                        </button>
                      </div>
                      <h4>
                        Your $WEE tokens will be locked for 8 days. After that
                        time you can withdraw any time.
                      </h4>
                    </div>
                  )}
                </div>
              )}

              {LotStatus === false && userRoyaBalance !== 0 && <TireStakeBox />}
            </div>
          </div>

          {/* <div className="applications-box1">
            <div className="applications-table-row">
              <div className="applications-list-item applications-header-item">
                <div className="applications-header-col">Tier</div>
                <div className="applications-header-col">Staking Lots</div>
                <div className="applications-header-col">NFT Lots</div>
                <div className="applications-header-col">Total Lots</div>
              </div>
              {SettlerLot > 0 && (
                <div className="applications-lis-item1">
                  <div className="applications-col">
                    <div className="applications-original-img1">
                      <img src={SETTLER.default} alt="" />
                    </div>
                    <a onClick={() => GoToPage(Paths.stakingLots)}>Settlers</a>
                  </div>
                  <div className="applications-col">{totalSNFTLot}</div>
                  <div className="applications-col">{totalSroyaLot}</div>
                  <div className="applications-col">{parseInt(totalSroyaLot) + parseInt(totalSNFTLot)}</div>
                </div>
              )}
              {MerchantLot > 0 && (
                <div className="applications-lis-item1">
                <div className="applications-col">
                  <div className="applications-original-img1">
                      <img src={MERCHANT.default} alt="" />
                    </div>
                    <a onClick={() => GoToPage(Paths.stakingLots)}>Merchants</a>
                  </div>
                  <div className="applications-col">{totalMNFTLot}</div>
                  <div className="applications-col">{totalMroyaLot}</div>
                  <div className="applications-col">{parseInt(totalMroyaLot) + parseInt(totalMNFTLot)}</div>
                </div>
              )}
              {QueenLot > 0 && (
                <div className="applications-lis-item1">
                  <div className="applications-col">
                    <div className="applications-original-img1">
                      <img src={ARCHON.default} alt="" />
                    </div>
                    <a onClick={() => GoToPage(Paths.stakingLots)}>Knights</a>
                  </div>
                  <div className="applications-col">{totalKNFTLot}</div>
                  <div className="applications-col">{totalKroyaLot}</div>
                  <div className="applications-col">{parseInt(totalKNFTLot) + parseInt(totalKroyaLot)}</div>
                </div>
              )}
              {KingLot > 0 && (
                <div className="applications-lis-item1">
                  <div className="applications-col">
                    <div className="applications-original-img1">
                      <img src={KNIGHT.default} alt="" />
                    </div>
                    <a onClick={() => GoToPage(Paths.stakingLots)}>Archons</a>
                  </div>
                  <div className="applications-col">{totalANFTLot}</div>
                  <div className="applications-col">{totalAroyaLot}</div>
                  <div className="applications-col">{parseInt(totalANFTLot) + parseInt(totalAroyaLot)}</div>
                </div>
              )}
              {RoyaleflashLot > 0 && (
                <div className="applications-lis-item1">
                  <div className="applications-col">
                    <div className="applications-original-img1">
                      <img src={MONARCH.default} alt="" />
                    </div>
                    <a onClick={() => GoToPage(Paths.stakingLots)}>Monarchs</a>
                  </div>
                  <div className="applications-col">{totalMoNFTLot}</div>
                  <div className="applications-col">{totalMoroyaLot}</div>
                  <div className="applications-col">{parseInt(totalMoNFTLot) + parseInt(totalMoroyaLot)}</div>
                </div>
              )}
            </div>
          </div>

          <div className="dashboard-inner-wrap">
            <div className="heading-row">
              <div className="headingbox">
                <h2>Active Allocation</h2>
              </div>
            </div>
            <div className="upcoming-projects-row">
              <div className="row">
                <div className="dashboard-row">
                  {[1].map((i: any) => (
                    <div className="col-md-3">
                      <div className="upcoming-box">
                        <div className="dashboard-upcoming-innerbox">
                          <div className="upcoming-cardbox">
                            <div className="dark-active-allocation-row">
                              <div className="active-allocation">
                                <img src={bg2Icon.default} alt="" />
                              </div>
                              <div className="dark-frontiers-logo">
                                <h6>
                                  Dark <br></br>Frontiers
                                </h6>
                                <div className="completeicon">
                                  {new Date(i.PoolStartDate * 1000) >
                                    new Date() && (
                                    <div className="completeicon-box">
                                      <h6>Registered</h6>
                                    </div>
                                  )}
                                  {new Date(i.PoolEndDate * 1000) >
                                    new Date() &&
                                    new Date(i.PoolStartDate * 1000) <
                                      new Date() && (
                                      <div className="completeicon-box">
                                        <h6>Running</h6>
                                      </div>
                                    )}
                                  {new Date(i.PoolEndDate * 1000) <
                                    new Date() && (
                                    <div className="completeicon-box">
                                      <h6>Completed</h6>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="dark-table">
                              <div className="dark-applications-lis-item">
                                <div className="staking-applications-col">
                                  <div className="dark-applications-original">
                                    ROYA Staked
                                  </div>
                                </div>
                                <div className="dark-applications-col">
                                  {totalRoyaValue}
                                </div>
                              </div>

                              <div className="dark-applications-lis-item">
                                <div className="staking-applications-col">
                                  <div className="dark-applications-original">
                                    NFT Staked
                                  </div>
                                </div>
                                <div className="dark-applications-col">0</div>
                              </div>

                              <div className="dark-applications-lis-item">
                                <div className="staking-applications-col">
                                  <div className="dark-applications-original">
                                    Contribution
                                  </div>
                                </div>
                                <div className="dark-applications-col">
                                  2 BNB
                                </div>
                              </div>

                              <div className="dark-applications-lis-item">
                                <div className="staking-applications-col">
                                  <div className="dark-applications-original">
                                    IDO Date
                                  </div>
                                </div>
                                <div className="dark-applications-col">
                                  {new Date(i.StatusDate).toLocaleDateString()}
                                </div>
                              </div>
                            </div>
                            <div className="upcoming-game-register-action">
                              {new Date(i.PoolEndDate * 1000) < new Date() &&
                                i.Status === "Whitelisted" && (
                                  <a
                                    onClick={() =>
                                      GoToGamedetailsPage(i.Selectedpool)
                                    }
                                    className="btn-action-link"
                                  >
                                    CLAIM TOKENS
                                  </a>
                                )}
                              {new Date(i.PoolEndDate * 1000) < new Date() &&
                                i.Status === "Pre-Selected KYC Open" && (
                                  <a href="#" className="btn-action-link">
                                    CLOSED
                                  </a>
                                )}
                              {new Date(i.PoolEndDate * 1000) < new Date() &&
                                i.Status === "Applied" && (
                                  <a href="#" className="btn-action-link">
                                    CLOSED
                                  </a>
                                )}
                              {new Date(i.PoolStartDate * 1000) > new Date() &&
                                i.Status === "Applied" && (
                                  <a
                                    onClick={() => GoToPage(Paths.stakingLots)}
                                    className="btn-action-link"
                                  >
                                    APPLIED
                                  </a>
                                )}
                              {i.Status === "Pre-Selected KYC Open" &&
                                new Date(i.PoolEndDate * 1000) > new Date() && (
                                  <a
                                    href={`${apiUrl}/fractal`}
                                    className="btn-action-link"
                                  >
                                    KYC OPEN
                                  </a>
                                )}
                              {new Date(i.PoolStartDate * 1000) < new Date() &&
                                new Date(i.PoolEndDate * 1000) > new Date() &&
                                i.Status === "Whitelisted" && (
                                  <a
                                    onClick={() =>
                                      GoToGamedetailsPage(i.Selectedpool)
                                    }
                                    className="btn-action-link"
                                  >
                                    JOIN SALE
                                  </a>
                                )}
                              {new Date(i.PoolStartDate * 1000) > new Date() &&
                                new Date(i.PoolEndDate * 1000) > new Date() &&
                                i.Status === "Whitelisted" && (
                                  <a
                                    onClick={() =>
                                      GoToGamedetailsPage(i.Selectedpool)
                                    }
                                    className="btn-action-link"
                                  >
                                    JOIN SALE
                                  </a>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="allocation-history">
            <div className="heading-row">
              <div className="headingbox">
                <h2>Allocation History</h2>
              </div>
            </div>
            <div className="allocation-history-table">
              <div className="applications-list-item applications-header-item">
                <div className="applications-header-col">Project Name</div>
                <div className="applications-header-col">IDO Date</div>
                <div className="applications-header-col">Status</div>
              </div>
              {userpooldata.length > 0 &&
                userpooldata.map((i: typeof Singledata) => (
                  <div className="applications-lis-item">
                    <div className="applications-col">
                      <div className="applications-original-img">
                        <img src={SideBar.default} alt="" />
                      </div>
                      <a
                        onClick={() => GoToGamedetailsPage(`${i.Selectedpool}`)}
                      >
                        Dark Frontiers
                      </a>
                    </div>
                    <div className="applications-col">
                      {new Date(i.StatusDate).toLocaleDateString()}
                    </div>
                    <div className="applications-col">
                      {i.Status === "Pre-Selected KYC Open" && (
                        <a href={`${apiUrl}/fractal`}>{i.Status}</a>
                      )}
                      {i.Status === "Applied" && (
                        <a onClick={() => GoToPage(Paths.stakingLots)}>
                          {i.Status}
                        </a>
                      )}
                      {i.Status === "Whitelisted" && (
                        <a
                          onClick={() =>
                            GoToGamedetailsPage(`${i.Selectedpool}`)
                          }
                        >
                          {i.Status}
                        </a>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div> */}

          {/* <div className="dashboard-roya-wrap">
            <div className="heading-row">
              <div className="headingbox">
                <h2>
                  <a
                    href="https://medium.com/officialroyale"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn About Ticker TBD
                  </a>
                </h2>
              </div>
            </div>
            <div className="dashboard-roya-row">
              {blogs.slice(0, 4).map(
                (blog: BlogItem, index: number) =>
                  blog.title !== "To Our Loyal Royale Community" && (
                    <div
                      key={index}
                      className="roya-article-box"
                      onClick={() => window.open(blog.link, "_blank")}
                    >
                      <div className="roya-article-img">
                        <img
                          src={
                            blog.thumbnail ? blog.thumbnail : ImgIcon.default
                          }
                          alt="medium blog"
                        />
                      </div>
                      <div className="roya-article-info">
                        <div className="title">{blog.title}</div>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{ __html: blog.content }}
                        ></div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div> */}
        </div>
        <Modal
          show={modalStatus === StakingLotModals.buysettler}
          closeModal={closeModalforlot}
        >
          <BuySettler closeModal={closeModalforlot} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.buymerchant}
          closeModal={closeModalforlot}
        >
          <BuyMerchant closeModal={closeModalforlot} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.buyKnight}
          closeModal={closeModalforlot}
        >
          <BuyKnight closeModal={closeModalforlot} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.buyArchon}
          closeModal={closeModalforlot}
        >
          <BuyArchon closeModal={closeModalforlot} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.buyMonarch}
          closeModal={closeModalforlot}
        >
          <BuyMonarch closeModal={closeModalforlot} />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellSettler}
          closeModal={closeModalforlot}
        >
          <SellSettlerRoya
            showLockModal={setshowMsg}
            lotBought={settlerLotbuy}
            nftLotBought={settlerNFTLotbuy}
            closeModal={closeModalforlot}
          />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellMerchant}
          closeModal={closeModalforlot}
        >
          <SellMerchantRoya
            showLockModal={setshowMsg}
            lotBought={merchantLotbuy}
            nftLotBought={merchantNFTLotbuy}
            closeModal={closeModalforlot}
          />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellKnight}
          closeModal={closeModalforlot}
        >
          <SellKnightRoya
            showLockModal={setshowMsg}
            lotBought={knightLotbuy}
            nftLotBought={knightNFTLotbuy}
            closeModal={closeModalforlot}
          />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellArchon}
          closeModal={closeModalforlot}
        >
          <SellArchonRoya
            showLockModal={setshowMsg}
            lotBought={archonLotbuy}
            nftLotBought={archonNFTLotbuy}
            closeModal={closeModalforlot}
          />
        </Modal>
        <Modal
          show={modalStatus === StakingLotModals.sellMonarch}
          closeModal={closeModalforlot}
        >
          <SellMonarchRoya
            showLockModal={setshowMsg}
            lotBought={monarchLotbuy}
            nftLotBought={monarchNFTLotbuy}
            closeModal={closeModalforlot}
          />
        </Modal>

        <Modal show={showMsg} closeModal={closeModal}>
          <div className="register-innerbg">
            <div className="successfully">
              <h4>
                Your $WEE tokens is locked for 8 days. After that time you are
                free to withdraw at any time.
              </h4>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Dashboard;
