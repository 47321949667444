// import { RoyaReserveModal } from "../../utils";
import {
    GET_POOLS_SUCCESS,
    GET_POOL_CREATOR_ADDRESS_SUCCESS,
    GET_SETTLER_LOT_BALANCE_SUCCESS,
    GET_USER_REGISTRATION_POOL_DETAILS,
    GET_MERCHANT_LOT_BALANCE_SUCCESS,
    GET_POOL_START_DATE_SUCCESS,
    GET_POOLS_SUCCESS_UPDATED,
    GET_TOKEN_NAME_DETAILS_SUCCESS,
    GET_TOKEN_SYMBOL_DETAILS_SUCCESS,
    GET_TOKEN_VALUE_IN_BNB_DETAILS_SUCCESS,
    GET_POOLS_WHITELIST_ADDRESS_SUCCESS_UPDATED,
    GET_TOKEN_COUNT_LEFT_VALUE_IN_BNB_DETAILS_SUCCESS,
    GET_TOKEN_COUNT_VALUE_IN_BNB_DETAILS_SUCCESS

} from "../actions";

const initialState = {
    poolsold: [],
    pools: [{
        PoolAddress: String,
        StartDate: Date,
        EndDate: Date,
        StakeRoya: Number,
        StakeNFT: Number,
        Contributation: Number,
        Applied: Boolean
    }],
    registerpools: [],
    poolcreator: "0",
    settlerLot: "0",
    merchantLot: "0",
    kingLot: "0",
    queenLot: "0",
    royalflashLot: "0",
    tokenname: "0",
    tokensymbol: "0",
    tokenvalue: "0",
    whitelistaddressdata: [],
    tokencountvalue: "0",
    tokencountleftvalue: "0"

};

const poolReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_POOLS_WHITELIST_ADDRESS_SUCCESS_UPDATED:
            return {
                ...state,
                whitelistaddressdata: action.whitelistaddressdata,
            };
        case GET_POOLS_SUCCESS:
            return {
                ...state,
                poolsold: action.poolsold,
            };
        case GET_USER_REGISTRATION_POOL_DETAILS:
            return {
                ...state,
                registerpools: action.data
            }
        case GET_POOLS_SUCCESS_UPDATED:
            return {
                ...state,
                pools: action.pools,
            };
        case GET_TOKEN_NAME_DETAILS_SUCCESS:
            return {
                ...state,
                tokenname: action.tokenname,
            };
        case GET_TOKEN_SYMBOL_DETAILS_SUCCESS:
            return {
                ...state,
                tokensymbol: action.tokensymbol,
            };
        case GET_POOL_CREATOR_ADDRESS_SUCCESS:
            return {
                ...state,
                pools: action.poolcreator,
            };
        case GET_SETTLER_LOT_BALANCE_SUCCESS:
            return {
                ...state,
                settlerLot: action.settlerLot,
            };
        case GET_MERCHANT_LOT_BALANCE_SUCCESS:
            return {
                ...state,
                merchantLot: action.merchantLot,
            };
        case GET_POOL_START_DATE_SUCCESS:
            return {
                ...state,
                pools: action.pools,
            };
        case GET_TOKEN_VALUE_IN_BNB_DETAILS_SUCCESS:
            return {
                ...state,
                tokenvalue: action.tokenvalue,
            };
        case GET_TOKEN_COUNT_VALUE_IN_BNB_DETAILS_SUCCESS:
            return {
                ...state,
                tokencountvalue: action.tokencountvalue,
            };
        case GET_TOKEN_COUNT_LEFT_VALUE_IN_BNB_DETAILS_SUCCESS:
            return {
                ...state,
                tokencountleftvalue: action.tokencountleftvalue,
            };


        default:
            return state;


    }
};

export default poolReducer;

