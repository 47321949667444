import { useLocation, Link } from "react-router-dom";
import {
  WeeweepadLogoGifImg,
  TelegramIcon,
  TwitterIcon,
} from "../../images";
import "./styles.scss";

const Footer = (): JSX.Element | null => {
  const location = useLocation();
  return (
    <footer className={location.pathname === "/" ? "last-section" : ""}>
      <div className="footercontainer">
        <div className="footerlogo">
          <Link to="/">
            <img src={WeeweepadLogoGifImg.default} alt="logo" style={{ paddingBottom: "50px" }} />
          </Link>
        </div>
        <p style={{ color: "white" }}>All rights reserved WeeWeePad 2024</p>
        <div className="footer-social-link">
          <ul className="social-link">
            <li>
              <a
                href="https://t.me/weeweepadwtf"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TelegramIcon.default} alt="Telegram" />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/weeweepadwtf"
                target="_blank"
                rel="noreferrer"
              >
                <img src={TwitterIcon.default} alt="Twitter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
