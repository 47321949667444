import * as Yup from 'yup';
import { AllocationType } from '../../utils';
import wallet from '../../utils/wallet';

export const schema = [
  Yup.object().shape({
    name: Yup.string()
      .test(
        'testing name',
        'pool name cannot be empty',
        (val: any) => val && val.trim().length
      )
      .required('Pool name is required'),
  }),
  Yup.object().shape({
    tokenAddress: Yup.string()
      .test('test address', 'Invalid token address', (val: any) =>
        val === null || wallet.web3.utils.isAddress(val)
      )
      .required('Token address is required'),
    tokenSymbol: Yup.string()
      .required('Token symbol is required'),
    decimals: Yup.string()
      .test(
        'test decimals',
        "Should be equal to token's decimal value",
        (val: any) => Number.isInteger(Number(val)) && Number(val) >= 0
      )
      .required('Token decimals is required')
  }),
  Yup.object().shape({
    tokenPrice: Yup.string()
      .test(
        'testing total price',
        'Token price should be greater than 0',
        (val: any) => !!Number(val) && parseFloat(val) > 0
      )
      .required('Token price is required'),
    totalTokens: Yup.string()
      .test(
        'testing total tokens',
        'Total tokens should be greater than 0',
        (val: any) => !!Number(val) && parseFloat(val) > 0
      )
      .required('Total tokens is required'),
    minCutoff: Yup.string()
      .test(
        'test cutoff',
        'Should be a percentage value with max 2 decimal places',
        (val: any) => {
          if (!!Number(val) && val.includes('.')) {
            return val.split('.')[1]?.length < 3;
          }
          return (
            (!!Number(val) && parseFloat(val) > 0 && parseFloat(val) <= 100) ||
            parseFloat(val) === 0
          );
        }
      )
      .required('Minimum cut off is required')
  }),
  Yup.object().shape({
    startDate: Yup.string()
      .test(
        'start date',
        'Start Date must be 60 mins from now',
        (val: any) =>
          parseInt(`${new Date(val).getTime() / 1000}`, 10) >=
          parseInt(`${new Date().getTime() / 1000 + 60 * 0}`, 10)
        // parseInt(`${new Date().getTime() / 1000 + 60 * 60 * 24 * 2}`, 10)
      )
      .required('Start date is required'),
    endDate: Yup.string()
      .test(
        'end date',
        'End date must be 60 mins from start date',
        function (val: any) {
          return (
            parseInt(`${new Date(val).getTime() / 1000}`, 10) >=
            parseInt(
              `${new Date(this.parent.startDate).getTime() / 1000 + 60 * 0}`,
              // `${new Date(this.parent.startDate).getTime() / 1000 + 60 * 60}`,
              10
            )
          );
        }
      )
      .required('End date is required')
  }),
  Yup.object().shape({
    minAllocation: Yup.string().test(
      'test min allocation',
      'Should be greater than 0 and less than max allocation, total tokens',
      function (val: any) {
        // if type is limited then value should be > 0
        let result = false;
        if (this.parent.minAllocationType === AllocationType.unlimited) {
          result = val === '-';
        }

        if (this.parent.minAllocationType === AllocationType.limited) {
          result =
            !!Number(val) &&
            parseFloat(val) > 0 &&
            parseFloat(this.parent.totalTokens) >= parseFloat(val);
        }
        return result;
      }
    ),
    maxAllocations: Yup.array().test(
      'test max allocation',
      'Should be greater than 0 and less than total tokens',
      function (val: any) {
        // if type is limited then value should be > 0
        let result = true;
        for (let i = 0; i < val.length; i++) {
          const allocation = val[i];
          const type = this.parent.maxAllocationTypes[i];

          result =
            type === AllocationType.limited
              ? !!Number(allocation) &&
                parseFloat(allocation) > 0 &&
                parseFloat(this.parent.totalTokens) >= parseFloat(allocation)
              : true;

          if (!result) break;
        }

        return result;
      }
    ),
    accessType: Yup.string().required('Access type is required'),
    allotEco: Yup.bool(),
    ecoRatio: Yup.string().test(
      'test eco ratio',
      'Eco ratio max 2 decimal places allowed',
      function (val: any) {
        if (this.parent.allotEco) {
          if (!!Number(val) && val.includes('.')) {
            return val.split('.')[1]?.length < 3;
          }
          return !!Number(val) && parseFloat(val) > 0;
        }
        return true;
      }
    ),
    // ppRatio: Yup.string()
    //   .required('Public/Private ratio is required')
    //   .test('test pp ratio', 'PP ratio max 2 decimal places allowed', (val: any) => {
    //     if (!!Number(val) && val.includes('.')) {
    //       return val.split('.')[1]?.length < 3;
    //     }
    //     return !!Number(val) && parseFloat(val) > 0;
    //   }),
    // holderRatio: Yup.string()
    //   .required('Holder ratio is required')
    //   .test('test holder ratio', 'Holder ratio max 2 decimal places allowed', (val: any) => {
    //     if (!!Number(val) && val.includes('.')) {
    //       return val.split('.')[1]?.length < 3;
    //     }
    //     return !!Number(val) && parseFloat(val) > 0;
    //   })
    //   .test('test ratio', 'Sum of ratios should be 100', function (val: any) {
    //     if (this.parent.allotEco) {
    //       return (
    //         parseFloat(this.parent.ecoRatio) + parseFloat(this.parent.ppRatio) + parseFloat(val) ===
    //         100
    //       );
    //     }
    //     return parseFloat(this.parent.ppRatio) + parseFloat(val) === 100;
    //   }),
    minAllocationType: Yup.string().required('Min allocation type is required'),
    maxAllocationTypes: Yup.array().required('Max allocation type is required')
  })
];
