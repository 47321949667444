/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSVLink } from 'react-csv';

import './styles.scss';

import { UploadCSVModal } from './upload-modal';

import { Game, Whitelist } from 'src/core';
import { DownloadIcon, ArrowLeftIcon, UploadWhiteIcon } from '../../../images';
import { getPoolContract, useAppSelector } from 'src/utils';

export const PrivateWhitelist = (): JSX.Element => {
  const history = useHistory();

  const [whitelists, setWhitelists] = useState<Array<Whitelist>>([]);
  const [gameInfo, setGameInfo] = useState<Game>();
  const [isBegin, setIsBegin] = useState<boolean>(false);

  const { walletConnected, userAddress } = useAppSelector(
    (state) => state.user
  );

  const [showAdd, setShowAdd] = React.useState(false);

  useEffect(() => {
    if (walletConnected) {
      findGameByUser();
    }
  }, [walletConnected]);

  const findPrivateWhitelist = async () => {
    if (!gameInfo?.poolInfo?.poolAddress) return;

    const results = await Whitelist.findPrivateWhitelist(
      gameInfo.poolInfo.poolAddress
    );
    setWhitelists(results);

    const isBegin = await checkStarted();
    setIsBegin(isBegin);
  };

  useEffect(() => {
    if (walletConnected && gameInfo) {
      findPrivateWhitelist();
    }
  }, [walletConnected, gameInfo, findPrivateWhitelist]);

  const findGameByUser = async () => {
    const { error, result } = await Game.findGameByUser();
    if (error) {
      return;
    }

    setGameInfo(result);

  };

  async function handleCSV(data?: Array<any>) {
    setShowAdd(false);

    if (!data) return;
    
    const filterData = data.filter(item => item.length === 5);

    const walletAddresses = filterData.map((x) => x[2]);

    if (gameInfo?.poolInfo?.poolAddress) {
      await getPoolContract(gameInfo?.poolInfo?.poolAddress)
        .methods.addPrivate(walletAddresses)
        .send({ from: userAddress });
      findPrivateWhitelist();
    }
  }

  const checkStarted = async () => {
    return await getPoolContract(gameInfo.poolInfo?.poolAddress)
      .methods.hasStarted()
      .call();
  };

  return (
    <div className="w-full my-game-wrapper flex-column account-private-whitelist">
      <div className="title-action" onClick={() => history.goBack()}>
        <img src={ArrowLeftIcon.default} alt="arrow left icon" />
        <span>Go Back</span>
      </div>
      <div className="admin-table-box">
        <div className="main-container">
          {walletConnected && gameInfo ? (
            <>
              <div className="table-header">
                <p>Private Whitelist for {gameInfo.name}</p>
                <div className="actions-container">
                  <CSVLink
                    className="btn-action-link"
                    data={whitelists}
                    filename={`${gameInfo.name}-safelist.csv`}
                    headers={[
                      { label: 'Wallet Address', key: 'walletAddress' },
                      { label: 'Tier', key: 'tier' }
                    ]}
                  >
                    <img src={DownloadIcon.default} alt="download icon" />
                    <span>Download CSV</span>
                  </CSVLink>
                  {
                    !isBegin &&
                    <button
                      className="btn-action-link"
                      onClick={() => setShowAdd(true)}
                    >
                      <img src={UploadWhiteIcon.default} alt="upload" />
                      <span>Add Private Whitelist</span>
                    </button>
                  }
                </div>
              </div>
              <div className="whitelist-table">
                <div className="table-row table-header">
                  <div>Wallet Address</div>
                </div>
                {whitelists.map((item, index) => (
                  <div key={index} className="table-row">
                    <div>{item.walletAddress}</div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              <h3>Please connect wallet first.</h3>
            </>
          )}
        </div>
      </div>
      {showAdd && <UploadCSVModal onClose={(data) => handleCSV(data)} />}
    </div>
  );
};
