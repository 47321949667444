import React from 'react';

import './styles.scss';

import { CloseIcon } from '../../images';

import clsxm from 'src/lib/clsxm';

export interface ActionProps {
  className?: string;
  label: string;
  type?: 'primary' | 'secondary';
  onClick?: () => void;
}

interface Props {
  className?: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  actions?: Array<ActionProps>;
  onClose: () => void;
  children: React.ReactNode;
}

export const NewModal: React.FC<Props> = ({
  className = '',
  title,
  description,
  actions,
  onClose = () => true,
  children
}) => {
  return (
    <div className="modal">
      <div className={clsxm('modal-wrapper', className)}>
        {title && <div className="modal-title">{title}</div>}
        {description && <div className="modal-description">{description}</div>}
        {children}
        {actions && (
          <div className="modal-actions">
            {actions.map((action, index) => {
              const {
                className = '',
                type = 'primary',
                label,
                onClick
              } = action;
              return (
                <button
                  key={index}
                  className={`btn-${type} ${className}`}
                  onClick={async () => {
                    type === 'primary' && await onClick?.();
                    type === 'secondary' && await onClose()
                  }}
                >
                  {label}
                </button>
              );
            })}
          </div>
        )}
        <button className="modal-close" onClick={() => onClose()}>
          <img src={CloseIcon.default} alt="close" />
        </button>
      </div>
    </div>
  );
};

export * from './csv-modal';
