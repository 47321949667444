import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import userReducer from "./user.reducer";
import stakeReducer from "./stake.reducer";
import rptStakingReducer from "./rptStaking.reducer";
import liquidityProviderReducer from "./liquidityProvider.reducer";
import stakingLotsReducer from "./stakingLots.reducer";
import royaReserveReducer from "./royaReserve.reducer";
import royaAnalyticsReducer from "./royaAnalytics.reducer";
import poolReducer from "./pool.reducer";
import profileReducer from './profile.reducer';
import gameReducer from "./game.reducer";
import blogsReducer from "./blogs.reducer";
import loadingReducer from "./loading.reducer";

const rootReducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  pools: poolReducer,
  stake: stakeReducer,
  games: gameReducer,
  liquidityProvider: liquidityProviderReducer,
  rptStaking: rptStakingReducer,
  stakingLots: stakingLotsReducer,
  royaReserve: royaReserveReducer,
  royaAnalytics: royaAnalyticsReducer,
  blogs: blogsReducer,
  loading: loadingReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
