import styled from "styled-components";
import { Theme, SecondaryGradientButton } from "../../../../utils";

export const ModalFragment = styled.div`
  background-image: url("../images/submit-form-wrapper.png");
  background-position: center;
  background-size: 100% 100%;
  padding: 50px 30px;
`;
export const Header = styled.div`
  text-align: left;
  color: var(--Black-Black-100, #000) !important;
  font-size: 30px;
  line-height: 120%;
  margin-top: 20px;
`;
export const Divider = styled.div`
  margin-top: 10px;
  margin-inline: auto;
  border: 1px solid rgba(79, 82, 88, 1);
`;
export const WalletWrapper = styled.div`
  margin-top: 25px;

  .metamask-icon {
    width: 60px;
    height: 60px;
  }

  .metamask-text {
    margin-top: 10.5px;
    color: #fff;
    font-weight: 700;
    text-align: -webkit-center;
  }

  .walletconnect-text {
    margin-top: 9px;
    color: #fff;
    font-weight: 700;
    text-align: -webkit-center;
  }

  .authereum-text {
    margin-top: 6px;
    color: #fff;
    font-weight: 700;
    text-align: -webkit-center;
  }

  .burner-text {
    margin-top: 6px;
  }

  .unilogin-text {
    margin-top: 28px;
  }

  .mew-text {
    margin-top: 9px;
  }
`;

export const WalletsContainer = styled.div`
  display: flex;

  @media (max-width: 500px) {
    // flex-direction: column;
  }
`;

interface WalletItemProps {
  selected: boolean;
}

export const WalletItem = styled.div<WalletItemProps>`
  flex: 1;
  padding: 16px 0 22px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;border-radius: 4px;
  border: ${(props) =>
    props.selected
      ? `2px solid var(--Black, #FFA12B)`
      : `2px solid var(--Black, #111)`
  };
  box-shadow: ${(props) => 
    props.selected
      ? `4px 4px 0px 0px #915101`
      : `4px 4px 0px 0px #111`
  };
  transition: 300ms all;
  background: white;
  margin-inline: 10px;
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 18px;

  :hover {
    // border: 1px solid ${Theme.linkColor};
    // border: 1px solid #000;
    transition: 300ms all;
    // background-color: ${Theme.selectedWalletBg};
    // background-color: #000000;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 18px;
  }
`;

export const WalletName = styled.div`
  font-size: 12px;
  line-height: 18px;
  color: black !important;
`;

export const ConnectCont = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

export const ConnectBtn = styled(SecondaryGradientButton)`
  width: 164px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
`;
