/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState, useEffect } from "react";
import { useAppSelector, TokenType } from "../../../../utils";
import {
  ImgWrapper,
  ImgContainer,
  CompanyName,
  CompanyType,
  RaiseContainer,
  HeaderPrimary,
  AmountRaised,
} from "./style";
import { GameModel, PoolInfoModel } from "src/logic/actions";
import React from "react";
import { powToNumber } from "src/utils/number";

interface Props {
  pool?: PoolInfoModel;
  bnbPrice: number;
  decimals: number;
  symbol: string;
  gameDetail?: GameModel;
  isSuccess: boolean;
  tokenLeft: string;
}

const PoolStatus: React.FC<Props> = ({
  pool,
  bnbPrice,
  decimals,
  isSuccess,
  symbol,
  gameDetail,
  tokenLeft
}) => {
  const { walletConnected, userAddress } = useAppSelector(
    (state) => state.user
  );
  const [amountRaised, setAmountRaised] = useState("");

  useEffect(() => {
    if (pool && walletConnected && userAddress) {
      if (pool.tokenVarious === 0) {
        const amountRaisedVal = (
          (+powToNumber(pool?.totalTokens) /
            Math.pow(10, pool.tokenDecimals) /
            +pool?.tokenPrice) *
          bnbPrice
        ).toFixed(2);
        setAmountRaised(amountRaisedVal);
      } else {
        const amountRaisedVal = (
          +powToNumber(pool?.totalTokens) /
          Math.pow(10, pool.tokenDecimals) /
          +pool?.tokenPrice
        ).toFixed(2);
        setAmountRaised(amountRaisedVal);
      }
    }
  }, [walletConnected, userAddress, bnbPrice, pool]);

  return (
    <>
      <ImgWrapper>
        <ImgContainer>
          <img src={gameDetail && gameDetail.thumbnail.split(',')[0]} alt="company" />
        </ImgContainer>
        <div>
          <CompanyName>{gameDetail && (gameDetail.name || "")}</CompanyName>
          <CompanyType>
            {pool ? (!pool.type ? "Public" : "Private") : "Coming soon"}
          </CompanyType>
        </div>
      </ImgWrapper>
      <RaiseContainer>
        <HeaderPrimary className="text-left">Fundraise goal</HeaderPrimary>
        <AmountRaised>{pool ? `${amountRaised}` : "TBA"} {TokenType[pool?.tokenVarious || 0]}</AmountRaised>
      </RaiseContainer>
      <RaiseContainer>
        <HeaderPrimary className="text-left">Trade Value</HeaderPrimary>
        <AmountRaised>
          {pool &&
            Math.round(
              +pool.totalTokens / Math.pow(10, pool.tokenDecimals)
            )}{" "}
          {pool && pool.tokenSymbol}
        </AmountRaised>
      </RaiseContainer>
      <RaiseContainer>
        <HeaderPrimary className="text-left">Remaining Tokens</HeaderPrimary>
        <AmountRaised>
          {pool && tokenLeft}{" "}
          {pool && pool.tokenSymbol}
        </AmountRaised>
      </RaiseContainer>
      {pool && pool.type === false && (
        <RaiseContainer>
          <HeaderPrimary className="text-left">
            Minimum Raise Limit
          </HeaderPrimary>
          <AmountRaised>{pool.minRaise / 100}%</AmountRaised>
        </RaiseContainer>
      )}
      <RaiseContainer>
        <HeaderPrimary className="text-left">Rate</HeaderPrimary>
        <AmountRaised>
          1 {TokenType[pool?.tokenVarious || 0]} ={" "}
          {pool ? +pool?.tokenPrice : "TBA"} {symbol}
        </AmountRaised>
      </RaiseContainer>

      {/*<BtnContainer>*/}
      {/*  {!isRegistered && regStarted && !regEnded ? (*/}
      {/*    <Select*/}
      {/*      disabled={!regStarted}*/}
      {/*      className="select"*/}
      {/*      value={userType}*/}
      {/*      onChange={handleChange}*/}
      {/*    >*/}
      {/*      <MenuItem value={UserTypes.NONROYAHOLDER}>*/}
      {/*        {UserTypes.NONROYAHOLDER}*/}
      {/*      </MenuItem>*/}
      {/*      <MenuItem value={UserTypes.ROYAHOLDER}>*/}
      {/*        {UserTypes.ROYAHOLDER}*/}
      {/*      </MenuItem>*/}
      {/*      <MenuItem value={UserTypes.ECOPARTNER}>*/}
      {/*        {UserTypes.ECOPARTNER}*/}
      {/*      </MenuItem>*/}
      {/*    </Select>*/}
      {/*  ) : null}*/}
      {/*</BtnContainer>*/}
    </>
  );
};

export default PoolStatus;
