export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const GET_ETH_BALANCE_SUCCESS = "GET_ETH_BALANCE_SUCCESS";

export const STAKE_START = "STAKE_START";

export const STAKE_SUCCESS = "STAKE_SUCCESS";

export const UPDATE_USDT_BALANCE = "UPDATE_USDT_BALANCE";

export const UPDATE_USDC_BALANCE = "UPDATE_USDC_BALANCE";

export const UPDATE_DAI_BALANCE = "UPDATE_DAI_BALANCE";

export const FETCH_LIQUIDITY_PROVIDER_SUCCESS =
  "FETCH_LIQUIDITY_PROVIDER_SUCCESS";

export const STAKE_APPROVE_START = "STAKE_APPROVE_START";

export const STAKE_APPROVE_SUCCESS = "STAKE_APPROVE_SUCCESS";

export const STAKE_APPROVE_FAILURE = "STAKE_APPROVE_FAILURE";

export const STAKE_SEND_START = "STAKE_SEND_START";

export const STAKE_SEND_SUCCESS = "STAKE_SEND_SUCCESS";

export const STAKE_SEND_FAILURE = "STAKE_SEND_FAILURE";

export const FETCH_RPT_BALANCE_SUCCESS = "FETCH_RPT_BALANCE_SUCCESS";

export const CLAIM_MROYA_START = "CLAIM_MROYA_START";

export const CLAIM_MROYA_SUCCESS = "CLAIM_MROYA_SUCCESS";

export const CLAIM_MROYA_FAILURE = "CLAIM_MROYA_FAILURE";

export const UNSTAKE_RTP_START = "UNSTAKE_RTP_START";

export const UNSTAKE_RTP_SUCCESS = "UNSTAKE_RTP_SUCCESS";

export const UNSTAKE_RTP_FAILURE = "UNSTAKE_RTP_FAILURE";

export const STAKE_RTP_START = "STAKE_RTP_START";

export const STAKE_RTP_SUCCESS = "STAKE_RTP_SUCCESS";

export const SWITCH_RPT_STAKE_STEP = "SWITCH_RPT_STAKE_STEP";

export const SWITCH_RPT_UNSTAKE_STEP = "SWITCH_RPT_UNSTAKE_STEP";

export const STAKE_RTP_FAILURE = "STAKE_RTP_FAILURE";

export const WITHDRAW_TOKEN_START = "WITHDRAW_TOKEN_START";

export const WITHDRAW_TOKEN_SUCCESS = "WITHDRAW_TOKEN_SUCCESS";

export const WITHDRAW_TOKEN_FAILURE = "WITHDRAW_TOKEN_FAILURE";

export const SWITCH_WITHDRAW_STEP = "SWITCH_WITHDRAW_STEP";

export const ROYA_RESERVE_STAKE_START = "ROYA_RESERVE_STAKE_START";

export const ROYA_RESERVE_STAKE_SUCCESS = "ROYA_RESERVE_STAKE_SUCCESS";

export const ROYA_RESERVE_STAKE_FAILURE = "ROYA_RESERVE_STAKE_FAILURE";

export const GET_ROYA_STAKE_BALANCE_SUCCESS = "GET_ROYA_STAKE_BALANCE_SUCCESS";

export const GET_ROYA_USER_BALANCE_SUCCESS = "GET_ROYA_USER_BALANCE_SUCCESS";

export const GET_REWARD_BALANCE_SUCCESS = "GET_REWARD_BALANCE_SUCCESS";

export const ACTIVATE_COOLDOWN_START = "ACTIVATE_COOLDOWN_START";

export const ACTIVATE_COOLDOWN_SUCCESS = "ACTIVATE_COOLDOWN_SUCCESS";

export const ACTIVATE_COOLDOWN_FAILURE = "ACTIVATE_COOLDOWN_FAILURE";

export const CLAIM_ROYA_REWARD_START = "CLAIM_ROYA_REWARD_START";

export const CLAIM_ROYA_REWARD_SUCCESS = "CLAIM_ROYA_REWARD_SUCCESS";

export const CLAIM_ROYA_REWARD_FAILURE = "CLAIM_ROYA_REWARD_FAILURE";

export const UNSTAKE_ROYA_RESERVE_START = "UNSTAKE_ROYA_RESERVE_START";

export const UNSTAKE_ROYA_RESERVE_SUCCESS = "UNSTAKE_ROYA_RESERVE_SUCCESS";

export const UNSTAKE_ROYA_RESERVE_FAILURE = "UNSTAKE_ROYA_RESERVE_FAILURE";

export const SELL_KNIGHT_ROYA_LOT_START = "SELL_KNIGHT_ROYA_LOT_START";

export const SELL_KNIGHT_ROYA_LOT_SUCCESS = "SELL_KNIGHT_ROYA_LOT_SUCCESS";

export const SELL_KNIGHT_ROYA_LOT_FAILURE = "SELL_KNIGHT_ROYA_LOT_FAILURE";

export const SELL_ARCHON_ROYA_LOT_START = "SELL_ARCHON_ROYA_LOT_START";

export const SELL_ARCHON_ROYA_LOT_SUCCESS = "SELL_ARCHON_ROYA_LOT_SUCCESS";

export const SELL_ARCHON_ROYA_LOT_FAILURE = "SELL_ARCHON_ROYA_LOT_FAILURE";

export const SELL_MONARCH_ROYA_LOT_START = "SELL_MONARCH_ROYA_LOT_START";

export const SELL_MONARCH_ROYA_LOT_SUCCESS = "SELL_MONARCH_ROYA_LOT_SUCCESS";

export const SELL_MONARCH_ROYA_LOT_FAILURE = "SELL_MONARCH_ROYA_LOT_FAILURE";

export const BUY_KNIGHT_ROYA_LOT_START = "BUY_KNIGHT_ROYA_LOT_START";

export const BUY_DISCOUNTED_QROYA_LOT_START = "BUY_DISCOUNTED_QROYA_LOT_START";

export const BUY_DISCOUNTED_SROYA_LOT_START = "BUY_DISCOUNTED_SROYA_LOT_START";

export const BUY_DISCOUNTED_MROYA_LOT_START = "BUY_DISCOUNTED_MROYA_LOT_START";


export const SELL_SETTLER_ROYA_LOT_START = "SELL_SETTLER_ROYA_LOT_START";

export const SELL_SETTLER_ROYA_LOT_SUCCESS = "SELL_SETTLER_ROYA_LOT_SUCCESS";

export const SELL_SETTLER_ROYA_LOT_FAILURE = "SELL_SETTLER_ROYA_LOT_FAILURE";

export const BUY_SETTLER_ROYA_LOT_START = "BUY_SETTLER_ROYA_LOT_START";

export const BUY_SETTLER_ROYA_LOT_SUCCESS = "BUY_SETTLER_ROYA_LOT_SUCCESS";

export const BUY_SETTLER_ROYA_LOT_FAILURE = "BUY_SETTLER_ROYA_LOT_FAILURE";


export const SELL_MERCHANT_ROYA_LOT_START = "SELL_MERCHANT_ROYA_LOT_START";

export const SELL_MERCHANT_ROYA_LOT_SUCCESS = "SELL_MERCHANT_ROYA_LOT_SUCCESS";

export const SELL_MERCHANT_ROYA_LOT_FAILURE = "SELL_MERCHANT_ROYA_LOT_FAILURE";

export const BUY_MERCHANT_ROYA_LOT_START = "BUY_MERCHANT_ROYA_LOT_START";

export const BUY_MERCHANT_ROYA_LOT_SUCCESS = "BUY_MERCHANT_ROYA_LOT_SUCCESS";

export const BUY_MERCHANT_ROYA_LOT_FAILURE = "BUY_MERCHANT_ROYA_LOT_FAILURE";


export const BUY_KNIGHT_ROYA_LOT_SUCCESS = "BUY_KNIGHT_ROYA_LOT_SUCCESS";

export const BUY_KNIGHT_ROYA_LOT_FAILURE = "BUY_KNIGHT_ROYA_LOT_FAILURE";

export const BUY_ARCHON_ROYA_LOT_START = "BUY_ARCHON_ROYA_LOT_START";

export const BUY_ARCHON_ROYA_LOT_SUCCESS = "BUY_ARCHON_ROYA_LOT_SUCCESS";

export const BUY_ARCHON_ROYA_LOT_FAILURE = "BUY_ARCHON_ROYA_LOT_FAILURE";

export const BUY_MONARCH_ROYA_LOT_START = "BUY_MONARCH_ROYA_LOT_START";

export const BUY_MONARCH_ROYA_LOT_SUCCESS = "BUY_MONARCH_ROYA_LOT_SUCCESS";

export const BUY_MONARCH_ROYA_LOT_FAILURE = "BUY_MONARCH_ROYA_LOT_FAILURE";

export const GET_USER_TOTAL_LOTS_SUCCESS = "GET_USER_TOTAL_LOTS_SUCCESS";

export const GET_USER_LOT_REWARD = "GET_USER_LOT_REWARD";

export const CLAIM_QLOT_REWARD_START = "CLAIM_QLOT_REWARD_START";

export const CLAIM_QLOT_REWARD_SUCCESS = "CLAIM_QLOT_REWARD_SUCCESS";

export const CLAIM_QLOT_REWARD_FAILURE = "CLAIM_QLOT_REWARD_FAILURE";

export const CLAIM_KLOT_REWARD_START = "CLAIM_KLOT_REWARD_START";

export const CLAIM_KLOT_REWARD_SUCCESS = "CLAIM_KLOT_REWARD_SUCCESS";

export const CLAIM_KLOT_REWARD_FAILURE = "CLAIM_KLOT_REWARD_FAILURE";

export const CLAIM_FLOT_REWARD_START = "CLAIM_FLOT_REWARD_START";

export const CLAIM_FLOT_REWARD_SUCCESS = "CLAIM_FLOT_REWARD_SUCCESS";

export const CLAIM_FLOT_REWARD_FAILURE = "CLAIM_FLOT_REWARD_FAILURE";

export const FETCH_LIQUID_RPT_SUCCESS = "FETCH_LIQUID_RPT_SUCCESS";

export const GET_USER_RPT = "GET_USER_RPT";

export const GET_USER_STAKED_RPT = "GET_USER_STAKED_RPT";

export const GET_USER_RPT_REWARD = "GET_USER_RPT_REWARD";

export const CLOSE_SUCCESS_MSG = "CLOSE_SUCCESS_MSG";

export const OPEN_ROYA_RESERVE_MODAL = "OPEN_ROYA_RESERVE_MODAL";

export const CLOSED_ROYA_RESERVE_MODAL = "CLOSED_ROYA_RESERVE_MODAL";

export const GET_COOLDOWN_STATUS_SUCCESS = "GET_COOLDOWN_STATUS_SUCCESS";

export const GET_CONTRIBUTION_DYNAMICS_SUCCESS =
  "GET_CONTRIBUTION_DYNAMICS_SUCCESS";

export const GET_TOTAL_STAKED_ROYA_SUCCESS = "GET_TOTAL_STAKED_ROYA_SUCCESS";

export const STAKERS_COOLDOWN_SUCCESS = "STAKERS_COOLDOWN_SUCCESS";

export const GET_MROYA_BALANCE_SUCCESS = "GET_MROYA_BALANCE_SUCCESS";

export const GET_TOTAL_RPT_SUPPLY_SUCCESS = "GET_TOTAL_RPT_SUPPLY_SUCCESS";

export const GET_NFT_BALANCE_SUCCESS = "GET_NFT_BALANCE_SUCCESS";

export const GET_NETWORK_LOT_SUCCESS = "GET_NETWORK_LOT_SUCCESS";

export const GET_ROYA_STATS_SUCCESS = "GET_ROYA_STATS_SUCCESS";

export const GET_TOTAL_STABLE_COIN_SUCCESS = "GET_TOTAL_STABLE_COIN_SUCCESS";

export const GET_RPT_SUPPLY_SUCCESS = "GET_RPT_SUPPLY_SUCCESS";

export const SET_NFT_ID = "SET_NFT_ID";

export const GET_LOCKED_NFT_SUCCESS = "GET_LOCKED_NFT_SUCCESS";

export const SELL_LOCKED_QUEEN_NFT_START = "SELL_LOCKED_QUEEN_NFT_START";

export const SELL_LOCKED_SETTLER_NFT_START = "SELL_LOCKED_SETTLER_NFT_START";

export const SELL_LOCKED_MERCHANT_NFT_START = "SELL_LOCKED_MERCHANT_NFT_START";

export const GET_DISCOUNTED_MERCHANT_LOT_SUCCESS =
  "GET_DISCOUNTED_MERCHANT_LOT_SUCCESS";

export const GET_DISCOUNTED_QUEEN_LOT_SUCCESS =
  "GET_DISCOUNTED_QUEEN_LOT_SUCCESS";

export const CLAIM_TOKENS_START = "CLAIM_TOKENS_START";

export const CLAIM_TOKENS_SUCCESS = "CLAIM_TOKENS_SUCCESS";

export const CLAIM_TOKENS_FAILURE = "CLAIM_TOKENS_FAILURE";

export const GET_ISCLAIMABLE_SUCCESS = "GET_ISCLAIMABLE_SUCCESS";

export const GET_TOTAL_ROYA_HOLDERS_SUCCESS = "GET_TOTAL_ROYA_HOLDERS_SUCCESS";

export const GET_CLAIMABLE_BALANCE_SUCCESS = "GET_CLAIMABLE_BALANCE_SUCCESS";

export const GET_ROYALE_LIQUID_SUCCESS = "GET_ROYALE_LIQUID_SUCCESS";

export const GET_TOTAL_MROYA_SUCCESS = "GET_TOTAL_MROYA_SUCCESS";

export const GET_RPT_DYNAMICS_SUCCESS = "GET_RPT_DYNAMICS_SUCCESS";

export const GET_ROYA_DYNAMICS_SUCCESS = "GET_ROYA_DYNAMICS_SUCCESS";

export const GET_RPT_TO_USD_SUCCESS = "GET_RPT_TO_USD_SUCCESS";

export const GET_OPTIMISER_LIQUIDITY_SUCCESS =
  "GET_OPTIMISER_LIQUIDITY_SUCCESS";

export const GET_GAMING_LIQUIDITY_SUCCESS = "GET_GAMING_LIQUIDITY_SUCCESS";

export const GET_GLOBAL_ROYA_PROTOCOL_SUCCESS =
  "GET_GLOBAL_ROYA_PROTOCOL_SUCCESS";

export const SWAP_MROYA_TOKEN_START = "SWAP_MROYA_TOKEN_START";

export const SWAP_MROYA_TOKEN_SUCCESS = "SWAP_MROYA_TOKEN_SUCCESS";

export const SWAP_MROYA_TOKEN_FAILURE = "SWAP_MROYA_TOKEN_FAILURE";

export const GET_RPT_REWARDS_SUCCESS = "GET_RPT_REWARDS_SUCCESS";

export const GET_REWARDS_DAYS_LEFT_SUCCESS = "GET_REWARDS_DAYS_LEFT_SUCCESS";

export const GET_STAKED_RPT_USDC = "GET_STAKED_RPT_USDC";

export const GET_REWARD_RATE_SUCCESS = "GET_REWARD_RATE_SUCCESS";

export const GET_POOLS_SUCCESS = "GET_POOLS_SUCCESS"

export const GET_POOL_CREATOR_ADDRESS_SUCCESS = "GET_POOL_CREATOR_ADDRESS_SUCCESS"


export const GET_USER_POOLS_SUCCESS_UPDATED = 'GET_USER_POOLS_SUCCESS_UPDATED'

export const GET_POOL_START_DATE_SUCCESS = "GET_POOL_START_DATE_SUCCESS"

export const GET_POOL_END_DATE_SUCCESS = "GET_POOL_END_DATE_SUCCESS"

export const GET_TOKEN_VALUE_IN_BNB_DETAILS_SUCCESS = "GET_TOKEN_VALUE_IN_BNB_DETAILS_SUCCESS"

export const GET_TOKEN_COUNT_VALUE_IN_BNB_DETAILS_SUCCESS = "GET_TOKEN_COUNT_VALUE_IN_BNB_DETAILS_SUCCESS"
export const GET_POOLS_SUCCESS_UPDATED = "GET_POOLS_SUCCESS_UPDATED"
export const GET_TOKEN_COUNT_LEFT_VALUE_IN_BNB_DETAILS_SUCCESS = "GET_TOKEN_COUNT_LEFT_VALUE_IN_BNB_DETAILS_SUCCESS"

export const GET_POOLS_WHITELIST_ADDRESS_SUCCESS_UPDATED = "GET_POOLS_WHITELIST_ADDRESS_SUCCESS_UPDATED"

export const GET_SETTLER_LOT_BALANCE_SUCCESS = "GET_SETTLER_LOT_BALANCE_SUCCESS"

export const GET_MERCHANT_LOT_BALANCE_SUCCESS = "GET_MERCHANT_LOT_BALANCE_SUCCESS"

export const GET_KNIGHT_LOT_BALANCE_SUCCESS = "GET_KNIGHT_LOT_BALANCE_SUCCESS"

export const GET_ARCHON_LOT_BALANCE_SUCCESS = "GET_ARCHON_LOT_BALANCE_SUCCESS"

export const GET_MONARCH_LOT_BALANCE_SUCCESS = "GET_MONARCH_LOT_BALANCE_SUCCESS"

export const GET_USER_REGISTRATION_POOL_DETAILS = "GET_USER_REGISTRATION_POOL_DETAILS"

export const GET_TOKEN_NAME_DETAILS_SUCCESS = "GET_TOKEN_NAME_DETAILS_SUCCESS"

export const GET_TOKEN_SYMBOL_DETAILS_SUCCESS = "GET_TOKEN_SYMBOL_DETAILS_SUCCESS"

export const GET_LAST_BOUGHT_SETTLER_LOT_SUCCESS = "GET_LAST_BOUGHT_SETTLER_LOT_SUCCESS"
export const GET_LAST_BOUGHT_MERCHANT_LOT_SUCCESS = "GET_LAST_BOUGHT_MERCHANT_LOT_SUCCESS"
export const GET_LAST_BOUGHT_KNIGHT_LOT_SUCCESS = "GET_LAST_BOUGHT_KNIGHT_LOT_SUCCESS"
export const GET_LAST_BOUGHT_ARCHON_LOT_SUCCESS = "GET_LAST_BOUGHT_ARCHON_LOT_SUCCESS"
export const GET_LAST_BOUGHT_MONARCH_LOT_SUCCESS = "GET_LAST_BOUGHT_MONARCH_LOT_SUCCESS"
export const GET_LAST_BOUGHT_SETTLER_NFT_LOT_SUCCESS = "GET_LAST_BOUGHT_SETTLER_NFT_LOT_SUCCESS"
export const GET_LAST_BOUGHT_MERCHANT_NFT_LOT_SUCCESS = "GET_LAST_BOUGHT_MERCHANT_NFT_LOT_SUCCESS"
export const GET_LAST_BOUGHT_KNIGHT_NFT_LOT_SUCCESS = "GET_LAST_BOUGHT_KNIGHT_NFT_LOT_SUCCESS"
export const GET_LAST_BOUGHT_ARCHON_NFT_LOT_SUCCESS = "GET_LAST_BOUGHT_ARCHON_NFT_LOT_SUCCESS"
export const GET_LAST_BOUGHT_MONARCH_NFT_LOT_SUCCESS = "GET_LAST_BOUGHT_MONARCH_NFT_LOT_SUCCESS"
export const GET_BALANCE = 'GET_BALANCE';

export const GET_ROYA_BALANCE_SUCCESS = 'GET_ROYA_BALANCE_SUCCESS';

export const GET_PARTICIPATING_POOLS_SUCCESS = 'GET_PARTICIPATING_POOLS_SUCCESS';

export const GET_POOL_REGISTRATION_ADDRESS_DETAILS_SUCCESS = 'GET_POOL_REGISTRATION_ADDRESS_DETAILS_SUCCESS';


export const GET_PARTICIPATING_POOLS_SUCCESS_NEW = 'GET_PARTICIPATING_POOLS_SUCCESS_NEW';

export const GET_POOLS_CREATED_BY_USER_SUCCESS = 'GET_POOLS_CREATED_BY_USER_SUCCESS';

export const GET_POOLS_DETAILS_FOR_USER_SUCCESS = 'GET_POOLS_DETAILS_FOR_USER_SUCCESS';

export const GET_GAMES_SUCCESS = 'GET_GAMES_SUCCESS';

export const SET_GAME_APPROVE_SUCCESS = 'SET_GAME_APPROVE_SUCCESS';

export const SET_GAME_DECLINE_SUCCESS = 'SET_GAME_DECLINE_SUCCESS';

export const SET_LOADING = 'SET_LOADING';