/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Button } from '../button';
import { useEffect, useState } from 'react';
import Web3 from 'web3';

// import { ReactComponent as TwitterIcon } from '../../images/twitter-2.svg';
// import { ReactComponent as FacebookIcon } from '../../images/facebook-2.svg';
// import { ReactComponent as WebIcon } from '../../images/web-icon.svg';
import { TwitterIcon, FacebookIcon, BrowserIcon } from '../../images';
import {
  PoolListItem,
  PollListItemTop,
  PollItemIcon,
  PoolItemNameContainer,
  PoolItemNameText,
  TimeContainer,
  PoolTime,
  PoolType,
  PoolTimeDot,
  PoolTimeText,
  PoolMidText,
  PoolFundText,
  AllocationContainer,
  AllocationItem,
  AllocationHeader,
  AllocationLine,
  AllocationValue,
  RegisterText,
  LoaderContainer,
  LoaderLine,
  BtnContainer,
  Icon,
  IconsContainer
} from './style';
import { CompanyImg } from '../../images';
import { Paths } from '../../utils';
import wallet from '../../utils/wallet';

const userTokenAbi = require('../../utils/abis/user-token.json');
const pancakeAbi = require('../../utils/abis/pancake.json');

interface Props {
  btnLabel?: string;
  applied?: boolean;
  selected?: boolean;
  rejected?: boolean;
  social?: boolean;
  data?: any;
}

export const getBnbPrice = async (): Promise<any> => {
  const mainnet = new Web3(
    new Web3.providers.HttpProvider('https://bsc-dataseed1.binance.org:443')
  );
  const pancakeLPinstance3 = new mainnet.eth.Contract(
    pancakeAbi.abi,
    '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16'
  );
  const reserveOfBUSDBNB: any = await pancakeLPinstance3.methods
    .getReserves()
    .call();
  const getBNBReserve: any = await reserveOfBUSDBNB['_reserve0'];
  const getUSDReserve: any = await reserveOfBUSDBNB['_reserve1'];
  const bnbPrice = getUSDReserve / getBNBReserve;
  return bnbPrice;
};

const PoolCard = ({
  btnLabel,
  applied,
  selected,
  rejected,
  social,
  data
}: Props): JSX.Element | null => {
  const history = useHistory();
  const [decimals, setDecimals] = useState<any>(0);
  const [bnbPrice] = useState(0);
  const [error, setError] = useState(false);

  const getDecimals = async () => {
    const token = new wallet.web3.eth.Contract(
      userTokenAbi.abi,
      data.tokenAddress
    );
    try {
      const decimalsValue = await token.methods.decimals().call();
      setDecimals(Number(decimalsValue));
    } catch (err) {
      setError(true);
    }
  };

  useEffect(() => {
    if (wallet.web3.utils.isAddress(data.tokenAddress)) {
      getDecimals();
      getBnbPrice();
    }
  }, [data.tokenAddress]);

  const handleClick = () => {
    history.push(`${Paths.poolDetails}/${data.poolAddress}`);
  };

  if (error) {
    return null;
  }

  if (bnbPrice) {
    const formattedMinValue = (
      data.minimumAllocationPerWallet / Math.pow(10, decimals)
    ).toFixed(2);
    const formattedMaxValue = (
      data.maximumAllocationPerWallet / Math.pow(10, decimals)
    ).toFixed(2);
    const amountRaised = (
      ((data.tokenPrice * (data.totalTokens / Math.pow(10, decimals))) /
        decimals) *
      bnbPrice
    ).toFixed(2);
    const regStartDate =
      new Date(data.utcStartDate * 1000).getTime() - 1000 * 60 * 60 * 24 * 6;
    const regEndDate =
      new Date(data.utcStartDate * 1000).getTime() - 1000 * 60 * 60 * 24 * 1;
    const regStarted: boolean = new Date(regStartDate).getTime() < Date.now();
    const regEnded: boolean = new Date(regEndDate).getTime() < Date.now();

    const regStartTime = moment(new Date(regStartDate)).fromNow();
    const regEndTime = moment(new Date(regEndDate)).fromNow();
    return (
      <PoolListItem>
        <PollListItemTop>
          <PollItemIcon>
            <img src={CompanyImg.default} alt="company pic" />
          </PollItemIcon>
          <PoolItemNameContainer>
            <PoolItemNameText>{data.name}</PoolItemNameText>
            <TimeContainer>
              <PoolTime>
                <PoolTimeDot />
                <PoolTimeText>
                  {moment(data.utcStartDate * 1000).fromNow()}
                </PoolTimeText>
              </PoolTime>
              <PoolType>{data.accessType ? 'Public' : 'Private'}</PoolType>
            </TimeContainer>
          </PoolItemNameContainer>
        </PollListItemTop>
        <PoolMidText>Raising</PoolMidText>
        <PoolFundText>$ {amountRaised}</PoolFundText>
        <AllocationContainer>
          <AllocationItem>
            <AllocationHeader>Min Allocations</AllocationHeader>
            {/* eslint-disable-next-line no-unneeded-ternary */}
            <AllocationValue>
              {Number(formattedMinValue) ? formattedMinValue : 0}
            </AllocationValue>
          </AllocationItem>
          <AllocationLine />
          <AllocationItem>
            <AllocationHeader>Max Allocations</AllocationHeader>
            {/* eslint-disable-next-line no-unneeded-ternary */}
            <AllocationValue>
              {Number(formattedMaxValue) ? formattedMaxValue : 0}
            </AllocationValue>
          </AllocationItem>
        </AllocationContainer>
        <RegisterText>
          {regEnded
            ? 'Registration Ended'
            : regStarted
            ? `Registration Ends ${regEndTime}`
            : `Registration Starts ${regStartTime}`}
        </RegisterText>
        <LoaderContainer>
          <LoaderLine />
        </LoaderContainer>

        <BtnContainer style={{ display: 'flex' }}>
          <Button onClick={handleClick} secondary={applied} error={rejected}>
            {btnLabel || 'View Details'}
          </Button>
          {social && (
            <IconsContainer>
              <Icon>
                <TwitterIcon />
              </Icon>
              <Icon>
                <FacebookIcon />
              </Icon>
              <Icon>
                <BrowserIcon />
              </Icon>
            </IconsContainer>
          )}
        </BtnContainer>
      </PoolListItem>
    );
  }
  return null;
};

export default PoolCard;
