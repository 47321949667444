import styled from "styled-components";

export const CollapseContainer = styled.div`
  background: #152246;
  border: 1px solid #2a3757;
  box-sizing: border-box;
  padding: 7px 20px 9px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const CollapseTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #ffffff;
`;

export const CollapseBtnCont = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
`;

export const Dash = styled.span`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #2cfee1;
  top: 50%;
  transform: translateY(-50%);
`;

export const DashRotate = styled.span`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #2cfee1;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
`;

interface CollapseContentProps {
  open: boolean;
}

export const CollapseContent = styled.div<CollapseContentProps>`
  border: ${(props) => (props.open ? "1px solid #2a3757" : "none")};
  max-height: ${(props) => (props.open ? "100%" : "0")};
  overflow: hidden;
  opacity: ${(props) => (props.open ? "1" : "0")};
  padding: ${(props) => (props.open ? "17px 20px 20px 20px;" : "0")};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
`;
