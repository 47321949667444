/* eslint-disable @typescript-eslint/no-explicit-any */
import { Transform, plainToInstance } from "class-transformer";
import { get } from "lodash";

import { Api } from "../../../lib/network";
import { BaseModel } from "../base";

import { POOL_BY_ID, UPSERT_POOL } from "./queries";
import { DateHelper } from "src/utils";

export class PoolInfo extends BaseModel {
  gameId: number;
  poolType: boolean;
  // type: boolean;
  name: string;
  poolAddress: string;
  poolCreator: string;
  tokenAddress: string;
  tokenDecimals: number;
  tokenSymbol: string;
  tokenPrice: string;
  tokenVarious: number;
  totalTokens: string;
  feePercentage: number;
  treasureAddress: string;
  feeAddress: string;
  currentStep: string;
  minRaise: string;
  @Transform(({ value }) => DateHelper.parse(value)) startTime: Date;
  @Transform(({ value }) => DateHelper.parse(value)) endTime: Date;
  enableLock: boolean = false;
  min1: number;
  max1: number;
  max2: number;
  max3: number;
  max4: number;
  max5: number;
  max6: number;
  isPrivate: boolean = false;
  ratio: number;

  static fromJson(json: any) {
    const result = plainToInstance(PoolInfo, json);
    return result;
  }
  static fromJsonArray(arrJson: any[]) {
    return arrJson ? arrJson.map((x) => PoolInfo.fromJson(x)) : [];
  }

  static async findPoolInfoById(id: number) {
    const { data, ...rest } = await Api.request(POOL_BY_ID, {
      id: id,
    });

    return {
      ...rest,
      results: get(data, "findPoolInfoById", []),
    };
  }

  static async upsertPool(params: any) {
    const { data, ...rest } = await Api.request(UPSERT_POOL, { data: params });

    return {
      ...rest,
      results: PoolInfo.fromJson(get(data, "upsertPoolInfo")),
    };
  }
}
