import { Pools } from '../../utils';
import * as Actions from '../actions';

interface ProfileState {
  royaBalance: string;
  tier: number;
  participatingPools: Array<Pools>;
  userCreatedPools: Array<Pools>;
  registrationdata: Array<any>;
  userpooldetails: Array<any>;
}

const initialState: ProfileState = {
  royaBalance: '0',
  tier: 0,
  participatingPools: [],
  userCreatedPools: [],
  registrationdata: [],
  userpooldetails: [],
};

const profileReducer = (
  state: ProfileState = initialState,
  actions: Actions.AppAction
): ProfileState => {
  switch (actions.type) {
    case Actions.GET_ROYA_BALANCE_SUCCESS:
      return {
        ...state,
        royaBalance: actions.royaBalance,
        tier: actions.tier,
      };
    case Actions.GET_POOL_REGISTRATION_ADDRESS_DETAILS_SUCCESS:
      return {
        ...state,
        registrationdata: actions.registrationdata
      };

    case Actions.GET_PARTICIPATING_POOLS_SUCCESS:
      return {
        ...state,
        participatingPools: actions.participatingPools,
      };

    case Actions.GET_POOLS_CREATED_BY_USER_SUCCESS:
      return {
        ...state,
        userCreatedPools: actions.userCreatedPools,
      };
    case Actions.GET_POOLS_DETAILS_FOR_USER_SUCCESS:
      return {
        ...state,
        userpooldetails: actions.userpooldetails,
      };

    default:
      return state;
  }
};

export default profileReducer;
