import { Fragment } from "react";
import { useDispatch } from 'react-redux';

import { setLoading } from "../../../../logic/actions/loading.action";
import {
  Header,
  ConnectCont,
  Divider,
} from "./style";
import { IconContainer } from "../connect/style";
import { WalletName } from "./style";
import { SepoliaLogoImg } from "src/images";

interface Props {
  show: boolean;
  closeModal: () => void;
}

const WrongNetwork = (props: Props) => {
  const dispatch = useDispatch();

  const { closeModal } = props;

  const handleChangeNetwork = () => {
    dispatch(setLoading(true));
    if (window.ethereum) {
      // @ts-ignore
      window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [
                  {
                      chainId:
                          process.env.REACT_APP_NETWORK_ID_HEX,
                  },
              ],
          })
          .then(() => {
            dispatch(setLoading(false));
            closeModal();
          }).catch((error)=>{
            console.log(error)
          })
  }

  };

  return (
      <Fragment>
      <Header>Change Network</Header>
      <Divider/>
      <IconContainer className="flex-column align-items-center mt-3">
        <div className="alert alert-danger p-1 px-3" role="alert">
        Wrong Network
        </div>
        <img src={SepoliaLogoImg.default} alt="wallet icon" width={100} />
        <WalletName className="metamask-text mt-1">Connect to Base</WalletName>
      </IconContainer>
      <ConnectCont className="mt-3">
        <button data-text="Connect" onClick={handleChangeNetwork}  className="btn-action-link" >Connect</button>
      </ConnectCont>
    </Fragment>
  );
};

export default WrongNetwork;
