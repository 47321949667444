import { get, isNil } from 'lodash';
import { destroyCookie, parseCookies, setCookie } from 'nookies';

export class Storage {
  static get domain() {
    return window.location.hostname;
  }
  static get cookiesContext() {
    return null;
  }
  static get cookiesPath() {
    return '/';
  }

  static get(key: string, defaultValue: any = null) {
    return localStorage.getItem(key) ?? defaultValue;
  }
  static set(key: string, value: any) {
    if (!isNil(value)) localStorage.setItem(key, value);
    else localStorage.removeItem(key);
  }

  static getCookies(key: string, defaultValue = null) {
    return get(parseCookies(), key, defaultValue);
  }
  static setCookies(
    key: string,
    value: any,
    path = Storage.cookiesPath,
    maxAge = 604800,
    sameSite = true
  ) {
    if (!isNil) {
      setCookie(null, key, value, {
        path,
        maxAge, // Expires after 7 days
        sameSite,
      });
    } else {
      destroyCookie(null, path)
    }
  }

  private static accessTokenKey = 'weeweepad-accessToken';
  static get accessToken() {
    return (
      Storage.get(Storage.accessTokenKey) ??
      Storage.getCookies(Storage.accessTokenKey)
    );
  }
  static set accessToken(value: string | undefined) {
    Storage.set(Storage.accessTokenKey, value);
    Storage.setCookies(Storage.accessTokenKey, value);
  }

  private static refreshTokenKey = 'weeweepad-refreshToken';
  static get refreshToken() {
    return (
      Storage.get(Storage.refreshTokenKey) ??
      Storage.getCookies(Storage.refreshTokenKey)
    );
  }
  static set refreshToken(value: string | undefined) {
    Storage.set(Storage.refreshTokenKey, value);
    Storage.setCookies(Storage.refreshTokenKey, value);
  }

  private static currentUserKey = 'royale-currentUser';
  static get currentUser() {
    return JSON.parse(
      Storage.get(Storage.currentUserKey) ??
      Storage.getCookies(Storage.currentUserKey)
    );
  }
  static set currentUser(value: any) {
    Storage.set(Storage.currentUserKey, JSON.stringify(value));
    Storage.setCookies(Storage.currentUserKey, JSON.stringify(value));
  }

  private static timezoneKey = 'royale-my-timezone';
  static get timezone() {
    return (
      Storage.get(Storage.timezoneKey) ??
      Storage.getCookies(Storage.timezoneKey)
    );
  }
  static set timezone(value: string | undefined) {
    Storage.set(Storage.timezoneKey, value);
    Storage.setCookies(Storage.timezoneKey, value);
  }

  private static acceptCookieKey = 'royale-accept-cookie';
  static get acceptCookie() {
    return Storage.get(Storage.acceptCookieKey);
  }
  static set acceptCookie(value: boolean | undefined) {
    Storage.set(Storage.acceptCookieKey, value);
  }
}
