import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff20;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  gap: 4px;
`;

interface RadioButtonProps {
  children: React.ReactNode;
}

export default function RadioOption({ children }: RadioButtonProps) {
  return <Container>{children}</Container>;
}
