import { useState } from "react";

import "./styles.scss";

import { RoyaStakeModel, thousandSeparator } from "../../../utils";

interface Props {
  model: RoyaStakeModel;
  totalRoyaLot: string;
  totalNFTLot: string;
  onClickStake: Function;
  onClickUnstake: Function;
}

export const RoyaStakeItem: React.FC<Props> = ({
  model,
  totalRoyaLot,
  totalNFTLot,
  onClickStake,
  onClickUnstake,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div className="staking-colitem">
      <div className="staking-colbox">
        <div className="staking-imgbox">
          <img src={model.img} alt={model.title} />
        </div>
        <div className="staking-content-info">
          <button
            aria-controls="tier-1-text"
            aria-expanded={collapsed}
            onClick={() => setCollapsed(!collapsed)}
          >
            <span className="staking-name">{model.title + " "}</span>
          </button>
          <span className="staking-token">
            ={thousandSeparator(model.price.toString())} Ticker TBD
          </span>
        </div>
      </div>
      <div className="staking-discount-row staking-discount-box">
        <button
          type="button"
          className="btn-action-link"
          onClick={() => onClickStake(model.buy)}
        >
          STAKE
        </button>
        <button
          type="button"
          className="btn-action-link"
          onClick={() => onClickUnstake(model.sell)}
        >
          UNSTAKE
        </button>
      </div>
    </div>
  );
};
