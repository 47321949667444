import {
  GET_POOLS_SUCCESS_UPDATED,
  GET_POOL_CREATOR_ADDRESS_SUCCESS,
  GET_SETTLER_LOT_BALANCE_SUCCESS,
  GET_MERCHANT_LOT_BALANCE_SUCCESS,
  GET_USER_REGISTRATION_POOL_DETAILS,
  GET_TOKEN_NAME_DETAILS_SUCCESS,
  GET_TOKEN_SYMBOL_DETAILS_SUCCESS,
  GET_POOL_START_DATE_SUCCESS,
  GET_POOL_END_DATE_SUCCESS,
  GET_POOLS_WHITELIST_ADDRESS_SUCCESS_UPDATED,
  GET_TOKEN_VALUE_IN_BNB_DETAILS_SUCCESS,
  GET_TOKEN_COUNT_VALUE_IN_BNB_DETAILS_SUCCESS,
  GET_TOKEN_COUNT_LEFT_VALUE_IN_BNB_DETAILS_SUCCESS,
  GET_USER_POOLS_SUCCESS_UPDATED,
} from "./constant";

import {
  getPoolDetailsContract,
  getsettlerLotBalance,
  getmerchantLotBalance,
  getPoolTokenDetailsContract,
} from "../../utils";

export const getPoolsWhitelistAddressSuccessUpdated = (
  whitelistaddressdata: any[]
) => {
  return {
    type: GET_POOLS_WHITELIST_ADDRESS_SUCCESS_UPDATED,
    whitelistaddressdata,
  };
};

export const getuserregistrationpooldetails = (data: any[]) => {
  return {
    type: GET_USER_REGISTRATION_POOL_DETAILS,
    data,
  };
};

export const getPoolsSuccessUpdated = (pools: any[]) => {
  return {
    type: GET_POOLS_SUCCESS_UPDATED,
    pools,
  };
};
export const getPoolCreatorSuccess = (pools: string) => {
  return {
    type: GET_POOL_CREATOR_ADDRESS_SUCCESS,
    pools,
  };
};
export const getSettlerLotBalanceSuccess = (settlerLot: string) => {
  return {
    type: GET_SETTLER_LOT_BALANCE_SUCCESS,
    settlerLot,
  };
};
export const getMerchantLotBalanceSuccess = (merchantLot: string) => {
  return {
    type: GET_MERCHANT_LOT_BALANCE_SUCCESS,
    merchantLot,
  };
};

export const getTokenValueInBNBDetailsSuccess = (tokenvalue: any) => {
  return {
    type: GET_TOKEN_VALUE_IN_BNB_DETAILS_SUCCESS,
    tokenvalue,
  };
};
export const getTokenCountValueInBNBDetailsSuccess = (tokencountvalue: any) => {
  return {
    type: GET_TOKEN_COUNT_VALUE_IN_BNB_DETAILS_SUCCESS,
    tokencountvalue,
  };
};
export const getTokenCountLeftValueInBNBDetailsSuccess = (
  tokencountleftvalue: any
) => {
  return {
    type: GET_TOKEN_COUNT_LEFT_VALUE_IN_BNB_DETAILS_SUCCESS,
    tokencountleftvalue,
  };
};

export const getTokenNameDetailsSuccess = (tokenname: any) => {
  return {
    type: GET_TOKEN_NAME_DETAILS_SUCCESS,
    tokenname,
  };
};

export const getTokenSymbolDetailsSuccess = (tokensymbol: any) => {
  return {
    type: GET_TOKEN_SYMBOL_DETAILS_SUCCESS,
    tokensymbol,
  };
};

export const getPoolStartDateSuccess = (pools: any[]) => {
  return {
    type: GET_POOL_START_DATE_SUCCESS,
    pools,
  };
};

export const getPoolEndDateSuccess = (pools: any[]) => {
  return {
    type: GET_POOL_END_DATE_SUCCESS,
    pools,
  };
};

export const getcost = (address: string) => async () => {
  try {
    const costvalue = await getPoolDetailsContract(address)
      .methods.cost()
      .call();
    return costvalue;
  } catch (e) {
    console.log("something went wrong in get pool creator addresses", e);
  }
};

export const getPoolCreator = (address: string) => async (dispatch: any) => {
  try {
    const creatorAddress = await getPoolDetailsContract(address)
      .methods.poolCreator()
      .call();
    dispatch(getPoolCreatorSuccess(creatorAddress));
  } catch (e) {
    console.log("something went wrong in get pool creator addresses", e);
  }
};

export const getPoolStartDate =
  (PoolAddress: string, pools: any[]) => async (dispatch: any) => {
    try {
      const startdate = await getPoolDetailsContract(PoolAddress)
        .methods.poolStartDate()
        .call();
      pools.forEach((pool) => {
        if (pool.PoolAddress === PoolAddress) {
          pool.StartDate = new Date(startdate * 1000);
        }
      });
      dispatch(getPoolStartDateSuccess(pools));
    } catch (e) {
      console.log("something went wrong in get pool start date", e);
    }
  };

export const getPoolEndDate =
  (PoolAddress: string, pools: any[]) => async (dispatch: any) => {
    try {
      const enddate = await getPoolDetailsContract(PoolAddress)
        .methods.endDate()
        .call();
      pools.forEach((pool) => {
        if (pool.PoolAddress === PoolAddress) {
          pool.EndDate = new Date(enddate * 1000);
        }
      });
      dispatch(getPoolEndDateSuccess(pools));
    } catch (e) {
      console.log("something went wrong in get pool start date", e);
    }
  };

export const getSettlerLotContractBalance =
  (address: string) => async (dispatch: any) => {
    try {
      const SettlerBalance = await getsettlerLotBalance()
        .methods.balanceOf(address)
        .call();
      dispatch(getSettlerLotBalanceSuccess(SettlerBalance));
    } catch (e) {
      console.log("something went wrong in get Settler tier balance", e);
    }
  };

export const getmerchantLotContractBalance =
  (address: string) => async (dispatch: any) => {
    try {
      const MerchantBalance = await getmerchantLotBalance()
        .methods.balanceOf(address)
        .call();
      dispatch(getMerchantLotBalanceSuccess(MerchantBalance));
    } catch (e) {
      console.log("something went wrong in get Settler tier balance", e);
    }
  };

export const getPoolTokenName = (address: any) => async (dispatch: any) => {
  try {
    const tokenaddress = await getPoolDetailsContract(address)
      .methods.ibep20()
      .call();
    const TokenName = await getPoolTokenDetailsContract(tokenaddress)
      .methods.name()
      .call();
    const TokenSymbol = await getPoolTokenDetailsContract(tokenaddress)
      .methods.symbol()
      .call();
    const TokenvalueinBNB = await getPoolDetailsContract(address)
      .methods.tradeValue()
      .call();
    const TokenLeft = await getPoolDetailsContract(address)
      .methods.tokensForSale()
      .call();
    const TokencountLeft = await getPoolDetailsContract(address)
      .methods.tokensLeft()
      .call();
    dispatch(getTokenNameDetailsSuccess(TokenName));
    dispatch(getTokenSymbolDetailsSuccess(TokenSymbol));
    dispatch(getTokenValueInBNBDetailsSuccess(TokenvalueinBNB));
    dispatch(getTokenCountValueInBNBDetailsSuccess(TokenLeft));
    dispatch(getTokenCountLeftValueInBNBDetailsSuccess(TokencountLeft));
  } catch (e) {
    console.log("something went wrong in get pool token data", e);
  }
};

export const swapTokenSale =
  (address: string, Amount: string, userAddress: string) =>
  async (dispatch: any) => {
    getPoolDetailsContract(address)
      .methods.swap(Amount)
      .send({ from: userAddress })
      .on("transactionHash", (hash: string) => {
        console.log("transaction hash approve $WEE token ", hash);
      })
      .on("receipt", (receipt: string) => {
        console.log("receipt approve $WEE token ", receipt);
        // dispatch(getMroyaBalance(userAddress));
      })
      .on("error", (error: string) => {
        console.log("error approve $WEE token ", error);
        // dispatch(swapMroyaTokenFailure());
      })
      .then(() => {});
  };
interface GetPoolDetailsSuccessType {
    type: typeof GET_POOLS_SUCCESS_UPDATED;
    pools: any[];
}

interface GetUserPoolDetailsSuccessType {
  type: typeof GET_USER_POOLS_SUCCESS_UPDATED;
  userpools: any[];
}

export const GetPoolDetailsSuccess = (
  pools: any[]
): GetPoolDetailsSuccessType => ({
  type: GET_POOLS_SUCCESS_UPDATED,
  pools,
});

export const GetUserPoolDetailsSuccess = (
  userpools: any[]
): GetUserPoolDetailsSuccessType => ({
  type: GET_USER_POOLS_SUCCESS_UPDATED,
  userpools,
});

export type PoolActions = GetPoolDetailsSuccessType | GetUserPoolDetailsSuccessType;
