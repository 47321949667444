import { useState, useEffect } from "react";
import { useAppSelector } from "../../../utils";
import { useDispatch } from "react-redux";
import { Sidebar } from "../sidebar";
import "./style.scss";
import { GameRegisterInfo } from "src/core";
import { GameDetailCard } from "src/components";
import { NoListImg } from "src/images";

export const ParticipatingGame = (): JSX.Element => {
  const [gameRegisterInfo, setGameRegisterInfo] = useState<any>();
  const dispatch = useDispatch();
  const { walletConnected, userAddress } = useAppSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (walletConnected) {
      getParticipatingGamesByUser();
    }
  }, [walletConnected, userAddress, dispatch]);

  const getParticipatingGamesByUser = async () => {
    const result = await GameRegisterInfo.getParticipatingGamesByUser();
    if (!result.error) {
      setGameRegisterInfo(result.results);
    }
  };

  return (
    <div className="my-game-wrapper">
      <Sidebar />
      <div className="content">
        <div className="title-action participating-title">
          Participating memes
        </div>
        <div className="admin-table-box participating-wrap">
          <div className="main-container participating-container">
            {walletConnected ? (
              <>
                {gameRegisterInfo && gameRegisterInfo.length > 0 ? (
                  gameRegisterInfo.map((item: any, index: number) => (
                    <GameDetailCard className="game-list" value={item.game} key={index} />
                  ))
                ) : (
                  <div className="project-nosubmit-container">
                    <img src={NoListImg.default} alt="" />
                    <h3>You haven’t participated in any projects yet</h3>
                  </div>
                )}
              </>
            ) : (
              <div className="project-nosubmit-container">
                <h3>Please connect wallet first.</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
