import React from "react";

import {
  ModalContainer,
  ModalWrapper,
  CrossIconContainer,
  ModalBorder,
} from "./style";
import { CloseIcon } from "../../images";

interface Props {
  className?: string;
  width?: string;
  show: boolean;
  closeModal: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<Props> = ({
  className = "",
  width = "",
  show,
  closeModal = () => true,
  children,
}) => {
  return (
    <ModalWrapper show={show}>
      <ModalBorder modalWidth={width}>
        <ModalContainer className={className} modalWidth={width}>
          <CrossIconContainer onClick={closeModal}>
            <img src={CloseIcon.default} alt="close" />
            {/* <img src={ModalClose.default} alt="close" /> */}
          </CrossIconContainer>
          {children}
        </ModalContainer>
      </ModalBorder>
    </ModalWrapper>
  );
};

export default Modal;
