import { ErrorMessage, useFormikContext } from "formik";
import { InputField, ErrorContainer } from "../../style";
import clsxm from "src/lib/clsxm";

interface Props {
  values: any;
  setIsChanged: (val: boolean) => void;
}

const SwapDetails: React.FC<Props> = ({ values, setIsChanged }) => {
  const formikProps = useFormikContext();

  const temp = parseFloat(values.totalTokens) / parseFloat(values.tokenPrice);
  const raisedValue =
    temp > 0 && parseFloat(values.tokenPrice) > 0 ? temp : 0.0;

  const handleChange = (val: any) => {
    formikProps.setFieldValue("tokenVarious", val);
    setIsChanged(true);
  };

  const handleTokenPriceChange = (val: any) => {
    formikProps.setFieldValue("tokenPrice", val);
    setIsChanged(true);
  };

  const handleTotalTokensChange = (val: any) => {
    formikProps.setFieldValue("totalTokens", val);
    setIsChanged(true);
  };

  return (
    <>
      <div className="pool-step-01">
        <div className="pool-name-box">
          <div className="swap-top-grid">
            <div className="step-form-control short">
              <label>Token Price</label>
              <select
                className="submit-form-control"
                name="tokenVarious"
                value={values.tokenVarious}
                onChange={(e) => handleChange(e.target.value)}
              >
                <option
                  value="0"
                  placeholder="Token Price"
                >
                  1 BASE
                </option>
                <option
                  value="1"
                  placeholder="Token Price"
                >
                  1 USDT
                </option>
                <option
                  value="2"
                  placeholder="Token Price"
                >
                  1 USDC
                </option>
                <option
                  value="3"
                  placeholder="Token Price"
                >
                  1 BUSD
                </option>
                <option
                  value="4"
                  placeholder="Token Price"
                >
                  1 DAI
                </option>
              </select>
            </div>
            <div className="swap-cion" style={{ color: "gray" }}>
              =
            </div>
            <div className="step-form-control">
            <label>&nbsp;</label>
              <InputField
                type="text"
                name="tokenPrice"
                placeholder="Token Price"
                className="submit-form-control"
                onChange={(e) => handleTokenPriceChange(e.target.value)}
              />
            </div>
            <ErrorContainer className="token-price-error">
              <ErrorMessage name="tokenPrice" />
            </ErrorContainer>
            <div className="roya-text">{values.tokenSymbol}</div>
          </div>

          <div className="step-form-control">
            <label>Total Tokens</label>
            <InputField
              type="text"
              name="totalTokens"
              placeholder="Total Tokens"
              className="submit-form-control"
              onChange={(e) => handleTotalTokensChange(e.target.value)}
            />
            <ErrorContainer>
              <ErrorMessage name="totalTokens" />
            </ErrorContainer>
          </div>
          <div className="step-form-control">
            <label>Amount Raised</label>
            <span className="raised-amount">
              {values.tokenVarious === "0" && raisedValue
                ? `${raisedValue.toFixed(3)} BASE`
                : ""}
              {values.tokenVarious === "1" && raisedValue
                ? `${raisedValue.toFixed(3)} USDT`
                : ""}
              {values.tokenVarious === "2" && raisedValue
                ? `${raisedValue.toFixed(3)} USDC`
                : ""}
              {values.tokenVarious === "3" && raisedValue
                ? `${raisedValue.toFixed(3)} BUSD`
                : ""}
              {values.tokenVarious === "4" && raisedValue
                ? `${raisedValue.toFixed(3)} DAI`
                : ""}
            </span>
          </div>

          <div
            className={clsxm(
              "step-form-control",
              !values["poolType"] ? "" : "d-none"
            )}
          >
            <label>Minimum Cutoff Amount (%)</label>
            <InputField
              name="minCutoff"
              placeholder="Minimum Cutoff"
              className="submit-form-control"
            />
            <ErrorContainer>
              <ErrorMessage name="minCutoff" />
            </ErrorContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default SwapDetails;
