import { ButtonContainer, ImageContainer, Link } from "./style";
import { CheckMarkImg } from "../../../images";
import { Paths } from "../../../utils";

export const CongratScreen = ({ id }: { id: number }): JSX.Element => {
  return (
    <>
      <div className="submit-wrap">
        <div className="heading-row">
          <div className="headingbox1">
            <h2>Your Meme has been Submitted Successfully</h2>
          </div>
        </div>

        <ImageContainer>
          <img src={CheckMarkImg.default} alt="" />
        </ImageContainer>

        <ButtonContainer>
          <Link
            to={Paths.dashboard}
            className="btn-action-link bottom-border-none"
          >
            Back to dashboard
          </Link>
          <Link to={`${Paths.gameDetails}/${id}`} className="btn-action-link">
            Meme preview
          </Link>
          <Link to={`${Paths.account}/my-project`} className="btn-action-link">
            Check your memes
          </Link>
        </ButtonContainer>
      </div>
    </>
  );
};
