import styled, { keyframes } from 'styled-components';
import { PrimaryButton } from '../../../../utils';

export const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ImgContainer = styled.div`
  width: 82px;
  height: 82px;
  margin-right: 24px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const CompanyName = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000;
  @media only screen and (max-width: 1200px) {
    font-size: 30px;
    line-height: 40px;
  }
  @media only screen and (max-width: 990px) {
    font-size: 20px;
    line-height: 35px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const CompanyType = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding: 3px 11px;
  border-radius: 4px;
  background: var(--primary-orange-hover, #D0872C);
  box-shadow: 0px 2px 0px 1px #000, 0px -2px 0px 0px #915101 inset;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: var(--White-White-100, #FFF);
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.30);
  width: fit-content;
`;

export const AddressCont = styled.div`
  margin-bottom: 33px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #000;
  padding: 0 13px;
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    font-size: 12px;

    & > div {
      width: 25%;
      overflow-x: auto;
    }
    & > div:first-child {
      width: 75%;
    }
  }
`;

export const HeaderPrimary = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #9aa1ac;
  margin-bottom: 8px;
  text-align: center;

  &.text-left {
    text-align: left;
  }
  @media only screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const RaiseContainer = styled.div`
  margin-top: 49px;
  @media only screen and (max-width: 991px) {
    margin-top: 20px;
    margin-left: 0px;
  }
`;

export const AmountRaised = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 150%;
  color: #000;

  &.small {
    font-size: 48px;
  }
  @media only screen and (max-width: 500px) {
    font-size: 28px;
    margin-top: 0;
  }
`;

export const DateAndRate = styled.div`
  margin-top: 37px;
  display: flex;
  justify-content: space-between;
`;

export const DateAndRateText = styled.div`
  color: #000;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  @media only screen and (max-width: 500px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

export const RegisterText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 65px;
  margin-bottom: 7px;
  color: #9aa1ac;
`;

export const LoaderContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 2px;
  background: rgba(162, 184, 219, 0.15);
  border-radius: 5px;
`;

export const LoaderLine = styled.div`
  width: 50%;
  height: 100%;
  background: #2dd2ff;
  border-radius: 5px 0px 0px 5px;
`;

export const RegisterTimeText = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 40px;
  background: #18355b;
  border: 1px solid #18355b;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
`;

export const Desc = styled.div`
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9aa1ac;

  span {
    color: #fff;
  }
`;

export const OnlinePresence = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #7682a5;
`;

export const LogoContainer = styled.div`
  width: 105px;
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  & .select {
    width: 200px;
    border-radius: 20px;
    padding: 5px 15px;
    margin-left: 10px;
    font-weight: bold;
    background: linear-gradient(180deg, #25d9fd 0%, #2cfee1 100%);
    box-shadow: 0px 0px 20px rgb(45 210 255 / 60%);
    &::before {
      border: 0;
    }
    &.MuiInput-underline:hover:not(.Mui-disabled):before,
    &.MuiInput-underline:after {
      border: 0;
    }
    &.MuiInputBase-root.Mui-disabled,
    & .MuiSelect-select.Mui-disabled {
      cursor: not-allowed;
    }
  }
`;

export const RegisterBtn = styled(PrimaryButton)`
  width: 100%;
  height: 44px;
  background: linear-gradient(180deg, #25d9fd 0%, #2cfee1 100%);
  box-shadow: 0px 0px 20px rgba(45, 210, 255, 0.6);
  border-radius: 30px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  &:disabled,
  &[disabled] {
    color: rgba(0, 0, 0, 0.38);
    cursor: not-allowed;
  }
  @media only screen and (max-width: 500px) {
    margin: 16px auto;
  }
`;

export const ViewRulesBtn = styled.button`
  width: 180px;
  height: 44px;
  border: 1px solid #2cfee1;
  color: #2cfee1;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-left: 20px;
  background-color: transparent;
  border-radius: 30px;
  cursor: pointer;
`;

const slideIn = keyframes`
  from {
    height: 0;
    opacity: 0;
  }

  to {
    height: 40px;
    opacity: 1;
  }
`;

export const StyledToastAlert = styled.div`
  animation: ${slideIn} 0.8s linear;
  width: 100%;
  padding: 5px 0;
  margin-top: 16px;
  font-weight: bold;
  background-color: white;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;
