import {
  Header,
  InputContainerSecondary,
  RoyaUnstakeField,
  RoyaText,
  MaxButton,
  AmountText,
  WarningTextCenter,
  BuyLotButton,
  CancelLotButton,
  BtnContainerSecondary,
  ErrorText,
  LoadingText,
} from "../style";
import { Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { unstakeRoyaAmount } from "../../logic/actions";
import {
  renderTokenAmountText,
  fromRoya,
  toRoya,
  getConfig,
} from "../../utils";

const initialValues = {
  royaAmount: "",
};

interface Props {
  closeModal: () => void;
}

const RoyaReserveUnstake = (props: Props) => {
  const { closeModal } = props;

  const dispatch = useDispatch();

  const handleUnstakeRoya = (values: any) => {
    const { royaAmount } = values;

    if (walletConnected) {
      dispatch(unstakeRoyaAmount(userAddress, royaAmount));
    }
  };

  const { walletConnected, userAddress } = useSelector(
    (state: any) => state.user
  );

  const { isUnstaking, stakedRoyaBalance } = useSelector(
    (state: any) => state.royaReserve
  );

  const schema = Yup.object().shape({
    royaAmount: Yup.string()
      .required("Enter value")
      .test(
        "royaAmount",
        `Should be greater than or equal to 0`, 
        (val) => parseFloat(val) >= 1
      )
      .test(
        "InsufficientFunds",
        `Insufficient Funds`, 
        (val) => BigInt(toRoya(!!val ? val : "0")) <= BigInt(stakedRoyaBalance)
      ),
  });

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleUnstakeRoya}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Header>UNSTAKE Ticker TBD</Header>
          <InputContainerSecondary>
            <RoyaUnstakeField
              name="royaAmount"
              value={values.royaAmount}
              onValueChange={(vals: any) =>
                setFieldValue("royaAmount", vals.value)
              }
              decimalScale={"18"}
            />

            <RoyaText>Ticker TBD</RoyaText>

            <MaxButton
              type="button"
              onClick={() =>
                setFieldValue("royaAmount", fromRoya(stakedRoyaBalance))
              }
            >
              MAX
            </MaxButton>
          </InputContainerSecondary>

          <ErrorText>
            <ErrorMessage name="royaAmount" />
          </ErrorText>

          <AmountText className="notranslate m-l-0">
            Available to unstake:{" "}
            {!!stakedRoyaBalance &&
              renderTokenAmountText(fromRoya(stakedRoyaBalance))}
          </AmountText>

          <WarningTextCenter>
            Attention! If you choose to withdraw more $WEE halfway through the
            cooldown period from the WeeWee Reserve. the timer will reset to 8
            days.
          </WarningTextCenter>

          <BtnContainerSecondary>
            <BuyLotButton
              type="submit"
            >
              Unstake
            </BuyLotButton>
            <CancelLotButton type="button" onClick={closeModal}>
              Cancel
            </CancelLotButton>
          </BtnContainerSecondary>
          {isUnstaking && (
            <LoadingText>{getConfig().transactionText}</LoadingText>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default RoyaReserveUnstake;
