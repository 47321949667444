/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  CheckGreenIcon,
  UnCheckGreenIcon,
} from "../../../images";
import { getPoolContract, getTokenContract } from "../../../utils";
import { useSelector } from "react-redux";

interface Props {
  type?: number;
  status?: boolean;
  text?: string;
  url?: string;
  btnText?: string;
  iconStatus?: boolean;
  gameInfo: any;
  handleClick: (val: any) => void;
}

export const GameDetailContainer: React.FC<Props> = ({
  type,
  text,
  btnText,
  gameInfo,
  handleClick,
}) => {
  const { userAddress, walletConnected } = useSelector(
    (store: any) => store.user
  );
  const [newButtonLabel, setButtonLabel] = useState("");
  const [newStatus, setNewStatus] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const updateSectionStatus = async () => {
    const poolDeployed = gameInfo.poolInfo && gameInfo.poolInfo?.poolAddress;
    const gameApproved = gameInfo.isApproved;
    const setPoolFinished =
      gameInfo.poolInfo && gameInfo.poolInfo?.currentStep === 4;

    if (type === 1) {
      if (poolDeployed) {
        setButtonLabel("Deployed");
        setNewStatus(true);
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
    if (type === 2) {
      if (setPoolFinished) {
        setButtonLabel("Edit Pool");
        setNewStatus(true);
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
      // if (await checkStarted()) {
      //   setButtonDisabled(true);
      // }
    }
    if (type === 3 && setPoolFinished) {
      const approval = await getTokenContract(gameInfo.poolInfo?.tokenAddress)
        .methods.allowance(userAddress, gameInfo.poolInfo?.poolAddress)
        .call();
      const fundStatus = await checkTokenBalanceStatus();
      if (approval > 0 || fundStatus) {
        setNewStatus(true);
        setButtonLabel("Done");
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
    if (type === 4 && setPoolFinished) {
      const fundStatus = await checkTokenBalanceStatus();
      if (fundStatus) {
        setNewStatus(true);
        setButtonLabel("Fund Added");
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
    if (type === 5 && setPoolFinished) {
      const setTierLimit = await getPoolContract(gameInfo.poolInfo?.poolAddress)
        .methods.isSetTierLimit()
        .call();
      if (setTierLimit) {
        setNewStatus(true);
        setButtonLabel("Already Set");
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
    if (type === 6 && setPoolFinished) {
      const treasureAddr = await getPoolContract(gameInfo.poolInfo?.poolAddress)
        .methods.treasurerAddress()
        .call();
      if (Number(treasureAddr)) {
        setNewStatus(true);
        setButtonLabel("Already Set");
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    }
    // if (type === 7 && gameInfo.poolInfo && gameInfo.poolInfo?.feeAddress) {
    //   setNewStatus(true);
    //   setButtonLabel('Already Set');
    //   setButtonDisabled(true);
    // }
    // if (type === 8 && gameInfo.poolInfo && gameInfo.poolInfo?.feePercentage) {
    //   setNewStatus(true);
    //   setButtonLabel('Already Set');
    //   setButtonDisabled(true);
    // }
    if ((type === 9 || type === 10 || type === 11) && setPoolFinished) {
      const isBegin = await checkStarted();
      const whitelistAddr = await getPoolContract(
        gameInfo.poolInfo?.poolAddress
      )
        .methods.getWhitelistedAddresses()
        .call();
      if (isBegin) {
        setNewStatus(true);
        setButtonLabel("View");
        setButtonDisabled(false);
      } else {
        setButtonDisabled(false);
      }
      if (Number(whitelistAddr[0])) {
        setNewStatus(true);
      }
    }
    if ((type === 12 || type === 13) && setPoolFinished) {
      // also need to check whether withdrawed or not
      const isFinished = await checkFinished();
      if (!isFinished) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
        //setNewStatus(true);
        setButtonLabel("Withdraw");
      }
    }

    if (!gameApproved) {
      setButtonDisabled(true);
    }
  };

  const checkFinished = async () => {
    const isFinished = await getPoolContract(gameInfo.poolInfo?.poolAddress)
      .methods.hasFinalized()
      .call();

    return isFinished;
  };

  const checkStarted = async () => {
    const isBegin = await getPoolContract(gameInfo.poolInfo?.poolAddress)
      .methods.hasStarted()
      .call();

    return isBegin;
  };

  // const checkTreasuerAddress = async () => {
  //   const treasureAddress = await getPoolContract(
  //     gameInfo.poolInfo?.poolAddress
  //   )
  //     .methods.treasurerAddress()
  //     .call();

  //   return treasureAddress !== "" || treasureAddress !== "0x";
  // };

  const checkTokenBalanceStatus = async () => {
    return await getPoolContract(gameInfo.poolInfo?.poolAddress)
      .methods.isSaleFunded()
      .call();
  };
  
  useEffect(() => {
    if (gameInfo && walletConnected) {
      updateSectionStatus();
    }
  }, [gameInfo, walletConnected]);

  return (
    <div className="game-detail-row">
      <div className="game-detail-left">
        <img
          src={
            newStatus === true
              ? CheckGreenIcon.default
              : UnCheckGreenIcon.default
          }
          alt="uncheck status icon"
        />
        <p>{text}</p>
      </div>
      <div className="game-detail-right">
        <button
          disabled={buttonDisabled}
          onClick={() => handleClick(type)}
          className={`btn-action-link ${buttonDisabled && "noncursor-pointer"}`}
          style={{ display: buttonDisabled ? "none" : "inline-block" }}
        >
          {newStatus ? newButtonLabel : btnText}
        </button>
      </div>
    </div>
  );
};
